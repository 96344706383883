import { Entity, EntitySearchType } from '@toggle/toggle';
import React, { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SearchAssetItem } from '~/components/search-asset-item/SearchAssetItem';
import { SearchModalBase } from '~/components/search-modal-base/SearchModalBase';
import { useEntities } from '~/hooks/use-entities';
import { useTagsSearch } from '~/hooks/use-tags-search/useTagsSearch';

import * as S from './AssetSearchModal.styles';

export interface AssetSearchModalProps {
  isOpen: boolean;
  entityTags?: string[];
  pageSearchResultsLimit?: number;
  onClose: () => void;
  handleUpdateItems: (tags: string[]) => void;
}

export const AssetSearchModal: FC<AssetSearchModalProps> = ({
  isOpen,
  entityTags = [],
  pageSearchResultsLimit = 30,
  handleUpdateItems,
  onClose,
}) => {
  const { t } = useTranslation('widget');
  const [searchQuery, setSearchQuery] = useState('');

  const displayedTags = useMemo(
    () => entityTags.slice(0, pageSearchResultsLimit),
    [entityTags, pageSearchResultsLimit]
  );

  const { data: activeEntitiesData, isLoading: activeEntitiesIsLoading } =
    useEntities(displayedTags);

  const {
    tags: tagList,
    loading,
    searchTags,
  } = useTagsSearch({
    searchType: EntitySearchType.Entity,
  });

  const handleSearch = (value: string) => {
    searchTags(value);
    setSearchQuery(value);
  };

  const handleSelectItem = (entity: Entity) => {
    const isAlreadyInList = entityTags.includes(entity.tag);

    const updatedTags = isAlreadyInList
      ? entityTags.filter(tag => tag !== entity.tag)
      : [...entityTags, entity.tag];

    handleUpdateItems(updatedTags);
  };

  const dropdownItems = !searchQuery ? activeEntitiesData : tagList;

  const andMoreAssetsCount = entityTags.length - pageSearchResultsLimit;

  return (
    <SearchModalBase<Entity>
      data-testid="scenario-add-asset-search"
      isOpen={isOpen}
      isLoading={loading || activeEntitiesIsLoading}
      shouldCloseOnSelect={false}
      onClose={() => {
        onClose();
        setSearchQuery('');
      }}
      title={t('widget:search.addAsset')}
      dropdownItems={dropdownItems}
      selectItem={handleSelectItem}
      searchValue={searchQuery}
      value={searchQuery}
      handleSearch={handleSearch}
      placeholder={
        entityTags.length ? '' : t('widget:search.placeholderAssetOnly')
      }
      renderDropdownItem={(entity, _, isFocused) => (
        <SearchAssetItem
          entity={entity}
          isFocused={isFocused}
          highlighted={searchQuery}
          isAdded={entityTags.includes(entity.tag)}
        />
      )}
      noSearchResultsLabel={t('widget:search.noSearchResultsAssetOnly')}
      afterInListNode={
        andMoreAssetsCount > 0 && (
          <S.AndMoreAssetLabel>
            {t('widget:search.andMoreAssets', { count: andMoreAssetsCount })}
          </S.AndMoreAssetLabel>
        )
      }
    />
  );
};
