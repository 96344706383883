import { ScenariosCondition } from '@toggle/toggle';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ScenariosData } from '~/hooks/use-scenarios/useScenarios';
import { parseScenarioData } from '~/widgets/scenario/utils/scenario-table-data/scenario-table-data';
import {
  getHorizonColumns,
  getSummaryColumns,
  ScenarioTableData,
} from '~/widgets/scenario/utils/scenario-table-utils/scenario-table-utils';

import { useScenarioStore } from '../../hooks/use-scenario-store/useScenarioStore';
import { EmptyTable } from '../empty-table/EmptyTable';
import { ScenarioNoAssets } from '../scenario-empty-state/scenario-no-assets/ScenarioNoAssets';
import { ScenarioNoConditions } from '../scenario-empty-state/scenario-no-conditions/ScenarioNoConditions';
import { ScenarioNoFilteredAssets } from '../scenario-empty-state/scenario-no-filtered-assets/ScenarioNoFilteredAssets';
import { ScenarioUsageExceeded } from '../scenario-empty-state/scenario-usage-exceeded/ScenarioUsageExceeded';
import { ScenarioNoResults } from '../scenario-empty-state/ScenarioNoResults';
import { ScenarioLoader } from '../scenario-loader/ScenarioLoader';
import { ScenarioTable } from '../scenario-table/ScenarioTable';
import * as S from './ScenarioResults.styles';

export interface ScenarioResultsProps {
  activeHorizon: string;
  conditionsCount: number;
  data: ScenariosData[];
  disabled: boolean;
  entitiesTags: string[];
  noAssetsButtonLabel?: string;
  hasError: boolean;
  horizons: string[];
  isLoading: boolean;
  isSummary: boolean;
  lastConditions?: ScenariosCondition[];
  onAbort: () => void;
  onEditAssets: () => void;
  onAddConditions?: () => void;
  onRunConditions: () => void;
  onRowClick: (item: ScenarioTableData) => void;
  refetch: () => void;
}

export const ScenarioResults = ({
  activeHorizon,
  conditionsCount,
  data,
  disabled,
  entitiesTags,
  noAssetsButtonLabel,
  hasError,
  horizons,
  isLoading,
  isSummary,
  lastConditions,
  onAbort,
  onEditAssets,
  onAddConditions,
  onRowClick,
  onRunConditions,
  refetch,
}: ScenarioResultsProps) => {
  const { t } = useTranslation('scenario');

  const hasActiveAssetFilters = useScenarioStore(
    state => !!state.filterGroups.ASSET_FILTERS.activeFilterOptions.length
  );
  const hasExceededUsageLimit = useScenarioStore(
    state => state.hasExceededUsageLimit
  );

  const hasData = data.some(item => item.predictions.length > 0);
  const hasNoAssets = !entitiesTags.length;
  const hasNoConditions = !conditionsCount;

  if (isLoading) {
    return (
      <ScenarioLoader
        countEntities={entitiesTags.length}
        onAbort={onAbort}
        conditionsCount={conditionsCount}
      />
    );
  }

  if (hasExceededUsageLimit) {
    return (
      <EmptyTable entitiesTags={entitiesTags}>
        <ScenarioUsageExceeded
          assetsCount={entitiesTags.length}
          conditionsCount={conditionsCount}
        />
      </EmptyTable>
    );
  }

  if (hasNoAssets) {
    if (hasActiveAssetFilters) {
      return <ScenarioNoFilteredAssets />;
    } else {
      return (
        <>
          <ScenarioNoAssets
            label={noAssetsButtonLabel}
            onClick={onEditAssets}
            hasNoConditions={hasNoConditions}
          />
        </>
      );
    }
  }

  if (!lastConditions?.length || disabled) {
    return (
      <EmptyTable entitiesTags={entitiesTags}>
        <ScenarioNoConditions
          conditionsCount={conditionsCount}
          runConditionsOnClick={onRunConditions}
          assetsCount={entitiesTags.length}
          addConditionsOnClick={onAddConditions}
        />
      </EmptyTable>
    );
  }

  if (!hasData) {
    return (
      <EmptyTable entitiesTags={entitiesTags}>
        <ScenarioNoResults />
      </EmptyTable>
    );
  }

  const parsedData = parseScenarioData({ data, horizons, activeHorizon });
  const columns = isSummary ? getSummaryColumns(t) : getHorizonColumns(t);

  return (
    <S.ScenarioResultsRoot data-testid="scenario-results" $disabled={disabled}>
      <ScenarioTable
        data={parsedData}
        columns={columns}
        hasError={hasError}
        refetch={refetch}
        onRowClick={onRowClick}
      />
    </S.ScenarioResultsRoot>
  );
};
