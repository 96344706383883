import { ScenariosCondition, ScreenerFilter } from '@toggle/toggle';

import { ActiveFilterOptions } from '~/components/filters/Filters';
import {
  FilterOption,
  ToggleFilterProps,
} from '~/components/update-filter-options/updateFilterOptions';
import {
  FilterGroups,
  FilterState,
  ScenarioFilterGroupKeys,
  SharedFilterState,
} from '~/hooks/use-filter-actions/useFilterActions';
import { ScenariosData } from '~/hooks/use-scenarios/useScenarios';
import { create } from '~/stores/create-store/createStore';

const SCENARIO_PAGE_SEARCH_RESULTS_LIMIT = 30;

type FilterGroup = {
  group: ScenarioFilterGroupKeys;
  schemas: ScreenerFilter[];
};

export enum ScenarioPageModalType {
  addAssets = 'addAssets',
  addCondition = 'addConditions',
}

type ScenarioStore = {
  activeModalFilter: ScreenerFilter | null;
  lastEntitiesTags?: string[];
  scenarioPageEntityTags: string[];
  lastScenariosData: ScenariosData[];
  setLastScenariosData: (data: ScenariosData[]) => void;
  scenarioPageSearchResultsLimit: number;
  addColumnFilter: (props: ToggleFilterProps) => void;
  clearActiveModalFilter: () => void;
  clearAllActiveFilterOptions: () => void;
  clearAllColumnsFilters: () => void;

  clearColumnFilter: (id: string) => void;
  columnFilters: { id: string; value: FilterOption }[];
  initStore: (groups: FilterGroup[]) => void;
  setActiveModalFilter: (filter: ScreenerFilter) => void;
  setActiveScenarioPageModal: (modalType?: ScenarioPageModalType) => void;
  activeScenarioPageModal?: ScenarioPageModalType;
  showColumnFilterModal: (columnId: string) => void;
  setLastEntitiesTags: (entitiesTags?: string[]) => void;
  lastConditions: ScenariosCondition[] | undefined;
  setLastConditions: (conditions?: ScenariosCondition[]) => void;
  setScenarioPageEntityTags: (tags: string[]) => void;
  clearScenarioPageEntityTags: () => void;
  lastActiveFilterOptions: ActiveFilterOptions[];
  setLastActiveFilterOptions: (options: ActiveFilterOptions[]) => void;
  hasExceededUsageLimit: boolean;
  setHasExceededUsageLimit: (value: boolean) => void;
} & SharedFilterState<ScenarioFilterGroupKeys>;

export const useScenarioStore = create<ScenarioStore>((set, get) => ({
  activeModalFilter: null,
  filteredEntityTagsLength: 0,
  hasExceededUsageLimit: false,
  setHasExceededUsageLimit: value => set({ hasExceededUsageLimit: value }),
  lastActiveFilterOptions: [],
  setLastActiveFilterOptions: options =>
    set({ lastActiveFilterOptions: options }),
  lastScenariosData: [],
  setLastScenariosData: data => set({ lastScenariosData: data }),
  scenarioPageSearchResultsLimit: SCENARIO_PAGE_SEARCH_RESULTS_LIMIT,
  scenarioPageEntityTags: [],
  setActiveScenarioPageModal: modalType =>
    set({ activeScenarioPageModal: modalType }),
  addColumnFilter: (props: ToggleFilterProps) => {
    const newColumnFilter = {
      id: props.filterKey,
      value: props.option,
    };

    set(prev => ({
      columnFilters: [
        ...prev.columnFilters.filter(f => f.id !== newColumnFilter.id),
        newColumnFilter,
      ],
    }));
  },

  columnFilters: [],

  clearActiveModalFilter: () => {
    set({ activeModalFilter: null });
  },

  clearAllActiveFilterOptions: () =>
    set(state => {
      const clearedFilterGroups: FilterGroups<ScenarioFilterGroupKeys> = {
        SCENARIO_CONDITIONS: {
          ...state.filterGroups.SCENARIO_CONDITIONS,
          activeFilterOptions: [],
        },
        COLUMN_FILTERS: {
          ...state.filterGroups.COLUMN_FILTERS,
          activeFilterOptions: [],
        },
        ASSET_FILTERS: {
          ...state.filterGroups.ASSET_FILTERS,
          activeFilterOptions: [],
        },
      };

      return { filterGroups: clearedFilterGroups };
    }),

  clearAllColumnsFilters: () => {
    set(prevState => ({
      columnFilters: [],
      filterGroups: {
        ...prevState.filterGroups,
        ['COLUMN_FILTERS']: {
          ...prevState.filterGroups['COLUMN_FILTERS'],
          activeFilterOptions: [],
          activeFilters: [],
        },
      },
    }));
  },

  clearColumnFilter: id => {
    const { columnFilters, filterGroups } = get();

    const { activeFilterOptions, allFilters, activeFilters } =
      filterGroups['COLUMN_FILTERS'];

    set({
      columnFilters: columnFilters.filter(f => f.id !== id),
      filterGroups: {
        ...filterGroups,
        ['COLUMN_FILTERS']: {
          allFilters,
          activeFilterOptions: activeFilterOptions.filter(f => f.id !== id),
          activeFilters: activeFilters.filter(f => f.key !== id),
        },
      },
    });
  },

  initStore: groups => {
    const currentFilterGroups = get().filterGroups;
    const filterGroups = groups.reduce((acc, group) => {
      const filterState: FilterState = {
        allFilters: group.schemas,
        activeFilterOptions:
          // handle case when we already have activeFilterOptions
          // which where created from a different page(for example from screener)
          currentFilterGroups[group.group].activeFilterOptions,
        activeFilters: group.schemas.filter(f => f.visible),
      };
      acc[group.group] = filterState;
      return acc;
    }, {} as FilterGroups<ScenarioFilterGroupKeys>);

    set({ filterGroups, hasExceededUsageLimit: false });
  },
  filterGroups: {
    SCENARIO_CONDITIONS: {
      activeFilterOptions: [],
      activeFilters: [],
      allFilters: [],
    },
    COLUMN_FILTERS: {
      activeFilterOptions: [],
      activeFilters: [],
      allFilters: [],
    },
    ASSET_FILTERS: {
      activeFilterOptions: [],
      activeFilters: [],
      allFilters: [],
    },
  },

  setActiveModalFilter: (activeModalFilter: ScreenerFilter) => {
    set({ activeModalFilter });
  },
  showColumnFilterModal: columnId => {
    const { filterGroups } = get();

    const filter = filterGroups['COLUMN_FILTERS'].allFilters.find(
      columnFilter => columnFilter.key === columnId
    );

    if (filter) {
      set({
        activeModalFilter: filter,
      });
    }
  },
  setLastEntitiesTags: (lastEntitiesTags?: string[]) =>
    set({ lastEntitiesTags }),
  lastConditions: undefined,
  setLastConditions: (conditions?: ScenariosCondition[]) =>
    set({ lastConditions: conditions }),
  setScenarioPageEntityTags: (tags: string[]) => {
    set({
      scenarioPageEntityTags: tags,
    });
  },
  clearScenarioPageEntityTags: () =>
    set({
      scenarioPageEntityTags: [],
    }),
}));
