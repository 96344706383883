/* eslint-disable max-lines-per-function */
import { ColumnDef } from '@tanstack/react-table';
import {
  AssetLogo,
  Tooltip,
  TooltipTrigger,
  WithNAFallback,
} from '@toggle/design-system';
import { getPriceStatus } from '@toggle/helpers';
import {
  EarningsItemWithEntity,
  EconomicEventOutput,
  formatTicker,
  KeyDevsObjectWithEntity,
  MappedEntity,
} from '@toggle/toggle';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { enUS } from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TruncateTooltip } from '~/components/truncate-tooltip/TruncateTooltip';
import { getFormattedDates } from '~/widgets/earnings/utils/utils';

import { formatNumber } from '../dashboard/calendar-widget/calendar-event-drawer/drawer-content/utils/number-formatter';
import * as Shared from './calendar-shared-styles/CalendarSharedStyles.styles';
import * as S from './events-table/EventsTable.styles';
import { TabsType } from './utils';

const MAX_ENTITY_NAME_LENGTH = 40;
const SORT_UNDEFINED = 'last';

interface UseEventsColumnsProps {
  activeTab: string;
}

export const useEventsColumns = ({ activeTab }: UseEventsColumnsProps) => {
  const { t } = useTranslation(['calendar']);

  const earningsColumns: ColumnDef<EarningsItemWithEntity>[] = useMemo(
    () => [
      {
        id: 'reporting_date',
        header: t('calendar:table.time'),
        cell: props => {
          const { reporting_date: reportingDate } = props.row.original;
          const date = new Date(reportingDate);
          const { isBeforeNineThirtyAm, isAfterFourPm } =
            getFormattedDates(date);

          return (
            <WithNAFallback
              value={
                date !== undefined && (
                  <S.TimeWrapper>
                    <Tooltip
                      isTouchDevice={false}
                      trigger={TooltipTrigger.Hover}
                      label={
                        <span>
                          {formatInTimeZone(
                            date,
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                            'h:mmaaa zz',
                            {
                              locale: enUS,
                            }
                          )}
                        </span>
                      }
                    >
                      <S.TimeIconWrapper>
                        {isBeforeNineThirtyAm && (
                          <Shared.SunRise iconName="SunRise" />
                        )}
                        {isAfterFourPm && <Shared.Moon iconName="Moon" />}
                        {!isBeforeNineThirtyAm && !isAfterFourPm && (
                          <Shared.Sun iconName="Sun" />
                        )}
                      </S.TimeIconWrapper>
                    </Tooltip>
                    {format(date, 'HH:mm')}
                  </S.TimeWrapper>
                )
              }
            />
          );
        },
        accessorFn: row => new Date(row.reporting_date),
        sortingFn: 'datetime',
      },
      {
        id: 'ticker',
        header: t('calendar:table.ticker'),
        cell: props => {
          const { entity } = props.row.original;

          const mappedEntity = entity as MappedEntity;
          return (
            <WithNAFallback
              value={
                mappedEntity && (
                  <S.TickerWrapper>
                    <AssetLogo entity={mappedEntity} isDarkTheme size={20} />
                    <S.Ticker>{formatTicker(mappedEntity)}</S.Ticker>
                  </S.TickerWrapper>
                )
              }
            />
          );
        },
        accessorFn: row => row.entity?.ticker,
        sortUndefined: SORT_UNDEFINED,
      },
      {
        id: 'entity',
        header: t('calendar:table.name'),
        cell: props => {
          const { entity } = props.row.original;
          const entityName =
            entity?.name_short || entity?.name_full || entity?.name;

          return (
            <WithNAFallback
              value={
                entityName && (
                  <S.EntityName>
                    <TruncateTooltip
                      text={entityName}
                      atLength={MAX_ENTITY_NAME_LENGTH}
                    />
                  </S.EntityName>
                )
              }
            />
          );
        },

        accessorFn: row =>
          row.entity?.name_short || row.entity?.name_full || row.entity?.name,
        sortUndefined: SORT_UNDEFINED,
      },
      {
        id: 'eps.estimate',
        header: t('calendar:table.expectedEps'),
        cell: props => {
          const {
            eps: { estimate },
          } = props.row.original;

          return (
            <WithNAFallback
              value={
                estimate !== undefined && (
                  <span>{formatNumber(false, estimate)}</span>
                )
              }
            />
          );
        },
        accessorFn: row => row.eps.estimate,
        sortUndefined: SORT_UNDEFINED,
      },
      {
        id: 'reported',
        header: t('calendar:table.reported'),
        cell: props => {
          const {
            eps: { value },
          } = props.row.original;

          return (
            <WithNAFallback
              value={
                value !== undefined && <span>{formatNumber(false, value)}</span>
              }
            />
          );
        },
        accessorFn: row => row.eps.value,
        sortUndefined: SORT_UNDEFINED,
      },
      {
        id: 'revenue.surprise',
        header: t('calendar:table.surpriseAmount'),
        cell: props => {
          const {
            eps: { surprise },
          } = props.row.original;

          return (
            <WithNAFallback
              value={
                surprise !== undefined && (
                  <S.SurpriseWrapper $status={getPriceStatus(surprise)}>
                    {formatNumber(false, surprise)}
                  </S.SurpriseWrapper>
                )
              }
            />
          );
        },
        accessorFn: row => row.eps.surprise,
        sortUndefined: SORT_UNDEFINED,
      },
      {
        id: 'revenue.surprise_percentage',
        header: t('calendar:table.surprisePercentage'),
        cell: props => {
          const {
            eps: { surprise_percentage: surprisePercentage },
          } = props.row.original;

          return (
            <WithNAFallback
              value={
                surprisePercentage !== undefined && (
                  <S.SurpriseWrapper
                    $status={getPriceStatus(surprisePercentage)}
                  >
                    {formatNumber(true, surprisePercentage)}
                  </S.SurpriseWrapper>
                )
              }
            />
          );
        },
        accessorFn: row => row.eps.surprise_percentage,
        sortUndefined: SORT_UNDEFINED,
      },
    ],
    []
  );

  const economicsColumns: ColumnDef<EconomicEventOutput>[] = useMemo(
    () => [
      {
        id: 'date',
        header: t('calendar:table.time'),
        cell: props => {
          const { date } = props.row.original;

          return (
            <WithNAFallback
              check={!!date}
              value={<span>{format(new Date(date), 'HH:mm')}</span>}
            />
          );
        },
        accessorFn: row => new Date(row.date),
        sortingFn: 'datetime',
      },
      {
        id: 'name',
        header: t('calendar:table.eventName'),
        cell: props => {
          const { name, country } = props.row.original;

          return (
            <WithNAFallback
              check={!!name && !!country}
              value={
                <S.EventNameWrapper>
                  <S.StyledFlagIcon circular countryCode={country} />
                  <S.NortFontText>{name}</S.NortFontText>
                </S.EventNameWrapper>
              }
            />
          );
        },
        accessorFn: row => row.name,
        sortUndefined: SORT_UNDEFINED,
      },
      {
        id: 'category',
        header: t('calendar:table.eventCategory'),
        cell: props => {
          const { category } = props.row.original;

          return (
            <WithNAFallback
              check={!!category}
              value={<S.NortFontText>{category}</S.NortFontText>}
            />
          );
        },
        accessorFn: row => row.category,
        sortUndefined: SORT_UNDEFINED,
      },
      {
        id: 'period',
        header: t('calendar:table.refPeriod'),
        cell: props => {
          const { period } = props.row.original;

          return (
            <WithNAFallback
              check={!!period}
              value={<S.NortFontText>{period}</S.NortFontText>}
            />
          );
        },
        accessorFn: row => row.period,
        sortUndefined: SORT_UNDEFINED,
      },
      {
        id: 'consensus',
        header: t('calendar:table.expected'),
        cell: props => {
          const { consensus } = props.row.original;

          return (
            <WithNAFallback
              value={
                consensus !== null &&
                consensus !== undefined && (
                  <span>{formatNumber(false, consensus)}</span>
                )
              }
            />
          );
        },
        accessorFn: row => (row.consensus === null ? undefined : row.consensus),
        sortUndefined: SORT_UNDEFINED,
      },
      {
        id: 'reported',
        header: t('calendar:table.reported'),
        cell: props => {
          const { actual_unit: actualUnit, actual } = props.row.original;
          return (
            <WithNAFallback
              value={
                actual !== undefined &&
                actual !== null && (
                  <span>{formatNumber(actualUnit === '%', actual)}</span>
                )
              }
            />
          );
        },
        accessorFn: row => row.actual,
        sortUndefined: SORT_UNDEFINED,
      },
      {
        id: 'surprise',
        header: t('calendar:table.surprise'),
        cell: props => {
          const {
            surprise_unit: surpriseUnit,
            actual_unit: actualUnit,
            surprise,
          } = props.row.original;

          return (
            <WithNAFallback
              value={
                surprise !== undefined &&
                surprise !== null && (
                  <S.SurpriseWrapper $status={getPriceStatus(surprise)}>
                    {formatNumber(
                      (surpriseUnit ?? actualUnit) === '%',
                      surprise
                    )}
                  </S.SurpriseWrapper>
                )
              }
            />
          );
        },
        accessorFn: row => row.surprise,
        sortUndefined: SORT_UNDEFINED,
      },
    ],
    []
  );

  const companyColumns: ColumnDef<KeyDevsObjectWithEntity>[] = useMemo(
    () => [
      {
        id: 'effectiveDate',
        header: t('calendar:table.time'),
        cell: props => {
          const { effective_date: effectiveDate } = props.row.original;

          return (
            <WithNAFallback
              check={!!effectiveDate}
              value={
                <S.TimeWrapper>
                  {format(new Date(effectiveDate), 'HH:mm')}
                </S.TimeWrapper>
              }
            />
          );
        },
        accessorFn: row => new Date(row.effective_date),
        sortingFn: 'datetime',
      },
      {
        id: 'ticker',
        header: t('calendar:table.ticker'),
        cell: props => {
          const { entity } = props.row.original;

          const mappedEntity = entity as MappedEntity;
          return (
            <WithNAFallback
              check={!!mappedEntity}
              value={
                mappedEntity && (
                  <S.TickerWrapper>
                    <AssetLogo entity={mappedEntity} isDarkTheme size={20} />
                    <S.Ticker>{formatTicker(mappedEntity)}</S.Ticker>
                  </S.TickerWrapper>
                )
              }
            />
          );
        },
        accessorFn: row => row.entity?.ticker,
        sortUndefined: SORT_UNDEFINED,
      },
      {
        id: 'eventType',
        header: t('calendar:table.eventType'),
        cell: props => {
          const { event_type: eventType } = props.row.original;

          return (
            <WithNAFallback
              value={<S.NortFontText>{eventType}</S.NortFontText>}
            />
          );
        },
        accessorFn: row => row.event_type,
        sortUndefined: SORT_UNDEFINED,
      },
      {
        id: 'headline',
        header: t('calendar:table.headline'),
        cell: props => {
          const { headline } = props.row.original;

          return (
            <WithNAFallback
              check={!!headline}
              value={<S.Headline>{headline}</S.Headline>}
            />
          );
        },
        accessorFn: row => row.headline,
        sortUndefined: SORT_UNDEFINED,
      },
    ],
    []
  );

  switch (activeTab) {
    default:
      return earningsColumns;
    case TabsType.Economics:
      return economicsColumns;
    case TabsType.Company:
      return companyColumns;
  }
};
