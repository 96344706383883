import React, { cloneElement } from 'react';
import { matchPath, useLocation } from 'react-router';

import { ChatInput, ChatInputProps } from '~/components/chat';
import { Sidebar } from '~/components/sidebar/Sidebar';
import { AppRoute } from '~/routes';
import { appPaths } from '~/routes/app-paths';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { Widgets } from '~/views/widgets/Widgets';

import * as S from './View.styles';

export interface AccountViewProps {
  config: AppRoute;
  showSidebar: boolean;
}

export const View = ({ config, showSidebar }: AccountViewProps) => {
  const location = useLocation();
  const isBasicUser = useSubscription(state => state.isBasicUser());

  const { element } = config;

  const has100Height = [
    appPaths.chart,
    appPaths.screener,
    appPaths.watchlist,
    appPaths.news,
    appPaths.themeOverview,
    appPaths.assetOverview,
    appPaths.alfred,
  ].some(path =>
    matchPath({ path, end: false, caseSensitive: false }, location.pathname)
  );

  const content = cloneElement(element, {
    renderChatInput: (props?: ChatInputProps) =>
      isBasicUser ? null : <ChatInput {...props} />,
  });

  return (
    <S.ViewRoot $has100Height={has100Height}>
      {showSidebar && <Sidebar />}
      <Widgets />
      {content}
    </S.ViewRoot>
  );
};
