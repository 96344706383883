import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AssetSearchModal } from '~/components/asset-search-modal/AssetSearchModal';
import { WORKING_LIST_ID } from '~/config/constants';
import { useAssetsFilterEntityTags } from '~/hooks/use-assets-filter-entity-tags/useAssetsFilterEntityTags';
import { useFilterActions } from '~/hooks/use-filter-actions/useFilterActions';
import { useWorkingList } from '~/hooks/use-working-list/useWorkingList';
import { ScenarioAssistantModal } from '~/widgets/scenario/components/scenario-assistant-modal/ScenarioAssistantModal';
import { useScenarioAssistant } from '~/widgets/scenario/components/scenario-assistant-modal/use-scenario-assistant/useScenarioAssistant';
import { ScenarioConditions } from '~/widgets/scenario/components/scenario-conditions/ScenarioConditions';
import { SCENARIO_ASSET_FILTER_KEY } from '~/widgets/scenario/hooks/use-scenario-store/filter-utils';
import {
  ScenarioPageModalType,
  useScenarioStore,
} from '~/widgets/scenario/hooks/use-scenario-store/useScenarioStore';

import { ClearAllButton } from './header/clear-all-button/ClearAllButton';
import * as S from './Scenario.styles';

export const Scenario = () => {
  const { t } = useTranslation([
    'widget',
    'chart',
    'scenario',
    'screener',
    'scenarioCommander',
    'countries',
    'analyze',
    'common',
  ]);
  const [scenarioAssistantConditionId, setScenarioAssistantConditionId] =
    useState<string | undefined>();
  const { saveWorkingList } = useWorkingList();

  const {
    activeScenarioPageModal,
    filterGroups,
    scenarioPageEntityTags,
    scenarioPageSearchResultsLimit,
    setActiveScenarioPageModal,
    setScenarioPageEntityTags,
  } = useScenarioStore(state => ({
    activeScenarioPageModal: state.activeScenarioPageModal,
    filterGroups: state.filterGroups,
    scenarioPageEntityTags: state.scenarioPageEntityTags,
    scenarioPageSearchResultsLimit: state.scenarioPageSearchResultsLimit,
    setActiveScenarioPageModal: state.setActiveScenarioPageModal,
    setScenarioPageEntityTags: state.setScenarioPageEntityTags,
  }));
  const { addFilterOption } = useFilterActions({
    store: useScenarioStore,
    group: 'ASSET_FILTERS',
  });

  const { data: scenarioFiltersTags } = useAssetsFilterEntityTags(filterGroups);

  const resetAskedQuestions = useScenarioAssistant(
    state => state.resetAskedQuestions
  );

  useEffect(() => {
    setScenarioPageEntityTags(scenarioFiltersTags);
  }, [JSON.stringify(scenarioFiltersTags)]);

  const changeEditFilter = (conditionId: string) => {
    if (conditionId) {
      setScenarioAssistantConditionId(conditionId);
      setActiveScenarioPageModal(ScenarioPageModalType.addCondition);
    }
  };

  const handleUpdateItems = (modalTags: string[]) => {
    saveWorkingList(modalTags);
    setScenarioPageEntityTags(modalTags);
    addFilterOption({
      filterId: SCENARIO_ASSET_FILTER_KEY,
      filterKey: SCENARIO_ASSET_FILTER_KEY,
      option: {
        customHeaderTitle: undefined,
        hasBottomBorder: true,
        key: WORKING_LIST_ID,
        name: t('common:filters.workingList'),
        value: { workingList: 'working-list' },
      },
    });
  };

  return (
    <S.Root>
      <S.HeaderWrapper>
        <S.StyledTitle>{t('scenario:scenario')}</S.StyledTitle>
        <ClearAllButton />
      </S.HeaderWrapper>
      <ScenarioConditions
        entitiesTags={scenarioPageEntityTags}
        noAssetsButtonLabel={t('scenario:emptyState.addAssets')}
        onEditAssets={() =>
          setActiveScenarioPageModal(ScenarioPageModalType.addAssets)
        }
        onAddConditions={() => {
          resetAskedQuestions();
          setActiveScenarioPageModal(ScenarioPageModalType.addCondition);
        }}
        onEditAssistantCondition={changeEditFilter}
      />
      <ScenarioAssistantModal
        editFilterKey={scenarioAssistantConditionId}
        isOpen={activeScenarioPageModal === ScenarioPageModalType.addCondition}
        onClose={() => {
          setScenarioAssistantConditionId(undefined);
          setActiveScenarioPageModal();
        }}
        resetEditFilterKey={() => {
          setScenarioAssistantConditionId(undefined);
        }}
      />
      {activeScenarioPageModal === ScenarioPageModalType.addAssets && (
        <AssetSearchModal
          isOpen
          entityTags={scenarioPageEntityTags}
          pageSearchResultsLimit={scenarioPageSearchResultsLimit}
          onClose={() => setActiveScenarioPageModal()}
          handleUpdateItems={handleUpdateItems}
        />
      )}
    </S.Root>
  );
};
