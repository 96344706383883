import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { ScreenerFilter, WatchList } from '@toggle/toggle';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import * as StylesFilter from '~/components/filter/Filter.styles';
import { FilterLabel } from '~/components/filter/FilterLabel';
import { AssetsOptionsList } from '~/components/filters/components/filter-assets/AssetsOptionsList';
import { FilterResetButton } from '~/components/filters/components/filter-reset-button/FilterResetButton';
import {
  FilterOption,
  ToggleFilterProps,
} from '~/components/update-filter-options/updateFilterOptions';
import {
  INDEX_ID,
  SCREENER_ID,
  WATCHLIST_ID,
  WORKING_LIST_ID,
} from '~/config/constants';
import { useQueryParams } from '~/hooks/use-query-params/useQueryParams';
import { useWorkingList } from '~/hooks/use-working-list/useWorkingList';
import { appPaths } from '~/routes/app-paths';
import { useWatchlist } from '~/stores/use-watchlist/useWatchlist';
import { FilterSearch } from '~/views/screener/filter-search/FilterSearch';
import {
  ASSET_FILTERS_KEY,
  isFilterWithChildOptionActive,
} from '~/views/screener/use-screener-store/filter-utils';
import { useScreenerStore } from '~/views/screener/use-screener-store/useScreenerStore';
import { widgetPaths } from '~/views/widgets/widget-utils';

import {
  AssetsFilterOption,
  mapActiveFilterOptions,
  mapToFilterOptions,
} from '../utils';

interface FilterAssetsProps {
  filter: ScreenerFilter;
  filterOptions: FilterOption[];
  publicLists: WatchList[];
  withScreeners?: boolean;
  addFilterOption: (props: ToggleFilterProps) => void;
  resetActiveFilterOptions: () => void;
  enableSearchInFilterAssets?: boolean;
}

type AssetFilterValueKey =
  | typeof WORKING_LIST_ID
  | typeof WATCHLIST_ID
  | typeof SCREENER_ID
  | typeof INDEX_ID;

export const FilterAssets = ({
  filter,
  filterOptions,
  publicLists,
  withScreeners = true,
  addFilterOption,
  resetActiveFilterOptions,
  enableSearchInFilterAssets = true,
}: FilterAssetsProps) => {
  const { t } = useTranslation(['common']);
  const navigate = useNavigate();
  const watchLists = useWatchlist(store => store.lists);
  const savedScreens = useScreenerStore(state => state.savedScreens);
  const hasWorkingList = useWorkingList(store => !!store.workingList.length);
  const { setQueryParams } = useQueryParams();

  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleClear = () => {
    setSearchValue('');
  };

  const handleReset = (e: Event) => {
    e.preventDefault();
    resetActiveFilterOptions();
    setSearchValue('');
  };

  const isChecked = (optKey: string) =>
    mappedActiveOptions.some(f => f.key === optKey);

  const onFilterSelect = (props: ToggleFilterProps, active: boolean) => {
    if (!active) {
      addFilterOption(props);
    }
  };

  const valueKeyToCustomHeaderTitleMap: Record<
    AssetFilterValueKey,
    string | undefined
  > = {
    [WORKING_LIST_ID]: undefined,
    [WATCHLIST_ID]: t('common:filters.watchlist'),
    [SCREENER_ID]: t('common:filters.savedScreen'),
    [INDEX_ID]: t('common:filters.index'),
  };

  const workingListOption = hasWorkingList
    ? [
        {
          name: t('common:filters.workingList'),
          key: WORKING_LIST_ID,
          value: { workingList: 'working-list' },
          hasBottomBorder: true,
        } as AssetsFilterOption,
      ]
    : [];

  const watchlistOptions = useMemo(
    () =>
      mapToFilterOptions({
        items: watchLists,
        valueKey: WATCHLIST_ID,
        customHeaderTitle: valueKeyToCustomHeaderTitleMap[WATCHLIST_ID],
        hasBottomBorder: true,
        withDefaultOption: true,
        customOptionLabel: t('common:filters.createWatchlist'),
        customOptionCallback: () =>
          setQueryParams({ widget: widgetPaths.watchlistManagerModal }),
      }),
    [watchLists]
  );

  const screenOptions = useMemo(
    () =>
      withScreeners
        ? mapToFilterOptions({
            items: savedScreens,
            valueKey: SCREENER_ID,
            customHeaderTitle: valueKeyToCustomHeaderTitleMap[SCREENER_ID],
            hasBottomBorder: true,
            withDefaultOption: true,
            customOptionLabel: t('common:filters.createScreen'),
            customOptionCallback: () => navigate(appPaths.screener),
          })
        : [],
    [savedScreens, withScreeners]
  );
  const optionGroups = [
    workingListOption,
    watchlistOptions,
    screenOptions,
    mapToFilterOptions({
      items: publicLists,
      valueKey: INDEX_ID,
      customHeaderTitle: valueKeyToCustomHeaderTitleMap[INDEX_ID],
      hasBottomBorder: true,
    }),
  ];

  const activeWorkingListOption = filterOptions.find(
    f => f.key === WORKING_LIST_ID
  );
  const mappedActiveOptions = [
    ...(activeWorkingListOption ? [activeWorkingListOption] : []),
    ...mapActiveFilterOptions(watchLists, WATCHLIST_ID, filterOptions),
    ...mapActiveFilterOptions(savedScreens, SCREENER_ID, filterOptions),
    ...mapActiveFilterOptions(publicLists, INDEX_ID, filterOptions),
  ];
  const isParentOptionActive = isFilterWithChildOptionActive(filterOptions);

  const filteredOptions = optionGroups.flatMap(optionGroup => {
    const filteredGroup = optionGroup.filter(option =>
      option.name.toLowerCase().includes(searchValue.toLowerCase())
    );
    return filteredGroup.map((option, idx) => {
      const optionValueKey = Object.keys(
        option.value
      )[0] as AssetFilterValueKey;
      const parsedOption = { ...option };

      if (idx === 0) {
        parsedOption.customHeaderTitle =
          valueKeyToCustomHeaderTitleMap[optionValueKey];
      }

      if (idx === filteredGroup.length - 1) {
        parsedOption.hasBottomBorder = true;
      }

      return parsedOption;
    });
  });

  const noResultsFound = !filteredOptions.length;

  const triggerLabel =
    (filter.key === ASSET_FILTERS_KEY && filter.name) ||
    mappedActiveOptions.length
      ? filter.name
      : t('common:filters.selectAssetList');

  return (
    <RadixDropdownMenu.Root
      open={open}
      onOpenChange={isOpen => {
        if (!isOpen) {
          handleClear();
        }

        setOpen(isOpen);
      }}
    >
      <StylesFilter.Trigger
        data-filter={filter.key}
        data-testid="assets-filter"
        $active={isParentOptionActive}
      >
        <StylesFilter.TriggerLabel>{triggerLabel}</StylesFilter.TriggerLabel>
        <FilterLabel options={mappedActiveOptions} multiselect={false} />
        <StylesFilter.TriggerArrow iconName="ChevronLightDown" />
      </StylesFilter.Trigger>
      <RadixDropdownMenu.Portal>
        <StylesFilter.DropdownMenuContent
          align="start"
          sideOffset={5}
          collisionPadding={5}
        >
          {enableSearchInFilterAssets && (
            <FilterSearch
              value={searchValue}
              onChange={handleSearch}
              onClear={handleClear}
              searchPlaceholder={t('common:filters.searchInputPlaceholder')}
            />
          )}
          {isParentOptionActive && (
            <>
              <StylesFilter.Separator />
              <FilterResetButton handleReset={handleReset} />
            </>
          )}
          <AssetsOptionsList
            options={filteredOptions}
            isChecked={isChecked}
            onFilterSelect={onFilterSelect}
            filterKey={filter.key}
          />
          {noResultsFound && (
            <StylesFilter.NoResults>
              {t('common:filters.noResults')}
            </StylesFilter.NoResults>
          )}
        </StylesFilter.DropdownMenuContent>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  );
};
