import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFilterActions } from '~/hooks/use-filter-actions/useFilterActions';
import { useScenarios } from '~/hooks/use-scenarios/useScenarios';
import { useScenarioAssistant } from '~/widgets/scenario/components/scenario-assistant-modal/use-scenario-assistant/useScenarioAssistant';
import { useScenarioStore } from '~/widgets/scenario/hooks/use-scenario-store/useScenarioStore';

import * as S from './ClearAllButton.styles';

export const ClearAllButton = () => {
  const { t } = useTranslation('scenario');

  const {
    clearAllFilterOptions,
    setLastConditions,
    activeConditionOptions,
    activeColumnFilterOptions,
    activeAssetFilterOptions,
    scenarioPageEntityTags,
    clearScenarioPageEntityTags,
    setLastEntitiesTags,
    setLastScenariosData,
    setHasExceededUsageLimit,
  } = useScenarioStore(state => ({
    activeConditionOptions:
      state.filterGroups['SCENARIO_CONDITIONS'].activeFilterOptions,
    activeColumnFilterOptions:
      state.filterGroups['COLUMN_FILTERS'].activeFilterOptions,
    activeAssetFilterOptions:
      state.filterGroups['ASSET_FILTERS'].activeFilterOptions,
    clearAllFilterOptions: state.clearAllColumnsFilters,
    setLastConditions: state.setLastConditions,
    scenarioPageEntityTags: state.scenarioPageEntityTags,
    clearScenarioPageEntityTags: state.clearScenarioPageEntityTags,
    setLastEntitiesTags: state.setLastEntitiesTags,
    setLastScenariosData: state.setLastScenariosData,
    setHasExceededUsageLimit: state.setHasExceededUsageLimit,
  }));

  const {
    resetActiveFilterOptions: resetActiveScenarioConditionsFilterOptions,
  } = useFilterActions({
    store: useScenarioStore,
    group: 'SCENARIO_CONDITIONS',
  });

  const { resetActiveFilterOptions: resetActiveAssetFilterOptions } =
    useFilterActions({
      store: useScenarioStore,
      group: 'ASSET_FILTERS',
    });

  const { resetConditions, conditions: scenarioAssistantConditions } =
    useScenarioAssistant(state => ({
      resetConditions: state.resetConditions,
      conditions: state.conditions,
    }));

  const { abort, clear: clearScenarioTable } = useScenarios();

  const clearAllHandler = () => {
    abort();
    clearAllFilterOptions();
    resetActiveScenarioConditionsFilterOptions();
    resetActiveAssetFilterOptions();
    resetConditions();
    setLastConditions(undefined);
    setLastEntitiesTags(undefined);
    clearScenarioTable();
    clearScenarioPageEntityTags();
    setLastScenariosData([]);
    setHasExceededUsageLimit(false);
  };

  const shouldShowClearAll =
    !!activeConditionOptions.length ||
    !!activeColumnFilterOptions.length ||
    !!scenarioAssistantConditions.length ||
    !!activeAssetFilterOptions.length ||
    !!scenarioPageEntityTags?.length;

  if (!shouldShowClearAll) {
    return null;
  }

  return (
    <S.StyledButton
      data-testid="clear-all-btn"
      label={t('scenario:assets.clearAll')}
      iconName="Clear"
      variant="empty"
      size="small"
      onClick={clearAllHandler}
    />
  );
};
