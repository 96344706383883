import { groupBy } from '@toggle/helpers';
import { ScreenerFilter } from '@toggle/toggle';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomFilterModal } from '~/components/custom-filter-modal/CustomFilterModal';
import {
  FilterOption,
  FilterUseType,
} from '~/components/update-filter-options/updateFilterOptions';
import {
  AnyFilterStore,
  FilterGroupKeys,
  FilterState,
  useFilterActions,
  UseFilterActionsProps,
} from '~/hooks/use-filter-actions/useFilterActions';
import {
  ASSET_FILTERS_KEY,
  getParentFilterDisabledOptions,
  getParentFilterOptions,
  isFilterWithChildOptionActive,
  showMultiUseFilter,
} from '~/views/screener/use-screener-store/filter-utils';

import { AddMoreFilters } from '../add-more-filters/add-more-filters/AddMoreFilters';
import { Filter, FilterProps } from '../filter/Filter';
import { SearchTreeModal } from '../search-tree-modal/SearchTreeModal';
import { getSectorActiveOptions } from '../search-tree-modal/utils';
import { FilterAssets } from './components/filter-assets/FilterAssets';
import { FilterCompany } from './components/filter-company/FilterCompany';
import { FilterScreeners } from './components/filter-screeners/FilterScreeners';
import { FilterTree } from './components/filter-tree/FilterTree';

const FILTERS_WITH_LIST_TITLE = ['market', 'theme_exposure'];

export interface ActiveFilterOptions {
  id: string;
  filter: string;
  options: FilterOption[];
}

export interface FiltersProps<T extends FilterGroupKeys = FilterGroupKeys> {
  filterState: FilterState;
  group: T;
  showAddMoreFilters?: boolean;
  showCustomFilterModal?: boolean;
  showTriggerLabel?: boolean;
  store: AnyFilterStore<T>;
  onCustomRangeClick?: (filter: ScreenerFilter) => void;
  onFilterGroupsChange?: UseFilterActionsProps<T>['onFilterGroupsChange'];
}

export const Filters = <T extends FilterGroupKeys>({
  filterState,
  group,
  store,
  onFilterGroupsChange,
  onCustomRangeClick,
  showAddMoreFilters = true,
  showCustomFilterModal = !onCustomRangeClick,
  showTriggerLabel = true,
}: FiltersProps<T>) => {
  const { t } = useTranslation(['screener', 'scenario']);

  const {
    removeActiveFilters,
    addActiveFilters,
    addFilterOption,
    removeFilterOption,
    resetFilterOptions,
    resetActiveFilterOptions,
    removeFilter,
    setFilterOptions,
  } = useFilterActions({ store, group, onFilterGroupsChange });

  const { activeFilters, activeFilterOptions, allFilters } = filterState;
  const [activeCustomFilter, setActiveCustomFilter] =
    useState<ScreenerFilter>();
  const [activeFilterTree, setActiveFilterTree] = useState<ScreenerFilter>();

  const handleMoreFiltersChange = (filterName: string, checked: boolean) => {
    if (checked) {
      return removeActiveFilters(filterName);
    }

    const newFilter = allFilters.find(f => f.key === filterName);

    if (newFilter) {
      addActiveFilters(newFilter);
    }
  };

  const labels = {
    searchPlaceholder: t('screener:search'),
    resetLabel: t('screener:reset'),
    noResultsLabel: t('screener:noResults'),
    selectedLabel: t('screener:selected'),
    disabledTooltipLabel: t('screener:disabledTooltip'),
    multiselectFeatured: t('screener:multiselectFeatured'),
    remove: t('scenario:conditionDropdown.remove'),
    setCustomRange: t('screener:setCustomRange'),
  };

  const renderFilter = (
    props: Omit<
      FilterProps<T>,
      'labels' | 'addFilterOption' | 'removeFilterOption' | 'onCustomRangeClick'
    >
  ) => {
    const customRangeHandler = () => {
      if (showCustomFilterModal) {
        setActiveCustomFilter(props.filter);
      } else if (onCustomRangeClick) {
        onCustomRangeClick(props.filter);
      }
    };

    const showListTitle =
      !!props.filter.config?.children ||
      (!!props.id && FILTERS_WITH_LIST_TITLE.includes(props.id));
    const commonProps = {
      key: props.id,
      labels,
      addFilterOption,
      removeFilterOption,
      resetActiveFilterOptions,
      onCustomRangeClick: customRangeHandler,
      onRemove: removeFilter,
      showTriggerLabel,
      showListTitle,
      enableSearchInFilterAssets: false,
      ...props,
    };

    if (props.filter.modal) {
      const sectorOptions = getSectorActiveOptions({
        options: props.filter.config.options,
        activeOptions: props.filterOptions,
      });
      return (
        <FilterTree
          {...commonProps}
          filterOptions={sectorOptions}
          onClick={() => setActiveFilterTree(props.filter)}
          isModalOpen={!!activeFilterTree}
        />
      );
    }

    switch (props.id) {
      case 'company':
        return <FilterCompany {...commonProps} />;
      case ASSET_FILTERS_KEY:
        return (
          <FilterAssets
            {...commonProps}
            publicLists={[]}
            withScreeners={false}
          />
        );
      case 'screener':
        return <FilterScreeners {...commonProps} />;
      default:
        return <Filter {...commonProps} />;
    }
  };

  return (
    <>
      {activeFilters.map(filter => {
        const useType: FilterUseType = filter.dynamic
          ? 'multi-use'
          : 'single-use';
        const currentFilterOptions = activeFilterOptions.filter(
          f => f.filter === filter.key
        );
        const multiUseFilters =
          useType === 'multi-use' &&
          //filter out options with only children selected
          currentFilterOptions
            .filter(item => isFilterWithChildOptionActive(item.options))
            .map(activeFilterOption =>
              renderFilter({
                id: activeFilterOption.id,
                filter,
                filterOptions: activeFilterOption.options,
                multiselect: false,
                disabledOptions: filter.config.children
                  ? undefined
                  : currentFilterOptions
                      .filter(f => f.id !== activeFilterOption.id)
                      .flatMap(o => o.options),
              })
            );

        const filterOptions = currentFilterOptions.flatMap(o => o.options);
        const showFilter =
          useType !== 'multi-use' ||
          showMultiUseFilter(filter, currentFilterOptions);
        return (
          <Fragment key={filter.key}>
            {multiUseFilters}
            {showFilter &&
              renderFilter({
                useType,
                id: filter.key,
                filter,
                filterOptions: getParentFilterOptions({
                  filter,
                  currentFilterOptions,
                  filterOptions,
                }),
                resetFilterOptions: filter.dynamic
                  ? undefined
                  : resetFilterOptions,
                disabledOptions: getParentFilterDisabledOptions(
                  filter,
                  filterOptions
                ),
              })}
          </Fragment>
        );
      })}
      {showAddMoreFilters && (
        <AddMoreFilters
          filterGroups={Object.entries(groupBy(allFilters, f => f.category))}
          handleClick={handleMoreFiltersChange}
          activeFilters={activeFilters}
          searchPlaceholder={labels.searchPlaceholder}
          noResultsLabel={labels.noResultsLabel}
        />
      )}
      {activeCustomFilter?.config.custom && (
        <CustomFilterModal
          onClose={() => {
            setActiveCustomFilter(undefined);
          }}
          activeFilter={activeCustomFilter}
          addFilterOption={addFilterOption}
          addActiveFilters={addActiveFilters}
        />
      )}
      {activeFilterTree?.modal && (
        <SearchTreeModal
          title={t('screener:gics.sectorSearch')}
          activeFilter={activeFilterTree}
          activeFilterOptions={activeFilterOptions}
          onClose={() => setActiveFilterTree(undefined)}
          setFilterOptions={setFilterOptions}
          resetFilterOptions={resetFilterOptions}
        />
      )}
    </>
  );
};
