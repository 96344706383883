import { ButtonGroup, typographyV2 } from '@toggle/design-system';
import styled from 'styled-components';

import { HorizonSelector } from '../horizon-selector/HorizonSelector';

export const ScenarioToolbarRoot = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  background-color: var(--surface-default);
`;

export const AssetCounter = styled.div`
  display: flex;
  align-items: center;
`;

export const AssetCounterLabel = styled.span`
  ${typographyV2.bodyXs};
  color: var(--text-soft);
`;

export const RowCounterLabel = styled.span`
  ${typographyV2.bodyXs};
  color: var(--text-soft);
`;

export const TooltipLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
`;

export const LeftWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const RightWrapper = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;

  ${ButtonGroup.Styles.ButtonGroupItem} {
    padding: 2px 4px;
  }
`;

export const StyledHorizonSelector = styled(HorizonSelector)`
  ${ButtonGroup.Styles.ButtonGroupItem} {
    padding: 2px 4px;
  }
`;
