import { InsightFilterRequestSchema, insights } from '@toggle/toggle';
import { subWeeks } from 'date-fns';
import { format, toZonedTime } from 'date-fns-tz';

import { postEntity } from '~/services/entities/entity-service';
import { fetchSnakeByName } from '~/services/overview-widget/overview-widget-service';
import { InsightWidgetData } from '~/stores/use-chat/assistant-response.type';
import { apiFetch, ApiFetchResponse } from '~/utils/api-fetch/apiFetch';

import { formatCumulativeReturnSnake } from './utils/insight-utils';

const INSIGHTS_LIMIT_IN_WEEKS = 1;

export type GetFilteredInsightsProps = Omit<
  InsightFilterRequestSchema,
  'from_date' | 'to_date'
> & {
  from_date?: Date;
  to_date?: Date;
};

export const getFilteredInsights = ({
  topic,
  asset_cls,
  class: cls,
  direction,
  eco,
  entity,
  event,
  horizon,
  last_value__lte,
  market_cap_usd_bn__gte,
  market_cap_usd_bn__lt,
  median_return__gte,
  median_return__lt,
  sector,
  stars,
  sub_class,
  from_date = subWeeks(new Date(), INSIGHTS_LIMIT_IN_WEEKS),
  to_date,
  page,
  limit,
}: GetFilteredInsightsProps) => {
  const payload: InsightFilterRequestSchema = {
    topic,
    asset_cls,
    class: cls,
    direction,
    eco,
    entity,
    event,
    horizon,
    last_value__lte,
    market_cap_usd_bn__gte,
    market_cap_usd_bn__lt,
    median_return__gte,
    median_return__lt,
    sector,
    stars,
    sub_class,
    from_date: from_date.toISOString(),
    to_date: to_date?.toISOString(),
    page,
    limit,
  };

  Object.keys(payload).forEach(
    key =>
      payload[key as keyof InsightFilterRequestSchema] === undefined &&
      delete payload[key as keyof InsightFilterRequestSchema]
  );

  return apiFetch(insights.insightFilter.path, {
    schema: insights.insightFilter.schema,
    method: 'post',
    body: payload,
  });
};

export const getInsight = (id: string) => {
  return apiFetch(insights.insight.path(id), {
    schema: insights.insight.schema,
  });
};

const getInsightPredictions = (id: string) => {
  return apiFetch(insights.predictions.path(id), {
    schema: insights.predictions.schema,
  });
};

export const getInsightWidgetData = async (
  insightId: string
): Promise<ApiFetchResponse<InsightWidgetData>> => {
  const [insightRes, predictionRes] = await Promise.all([
    getInsight(insightId),
    getInsightPredictions(insightId),
  ]);

  if (insightRes.error) {
    return insightRes;
  }

  if (predictionRes.error) {
    return predictionRes;
  }

  const insight = insightRes.data;
  const predictions = predictionRes.data;

  const entityRes = await postEntity(insight.metadata.entity);

  if (entityRes.error) {
    return entityRes;
  }

  const entity = entityRes.data;

  const startDate = format(
    toZonedTime(
      new Date(insight.metadata.last_value_date ?? insight.created_at),
      'UTC'
    ),
    'yyyyMMdd'
  );

  const snake = await fetchSnakeByName(
    `${entity.tag}.${entity.primary_method}.cumulative_returns(date=${startDate})`
  );

  let snakeTs = [] as { index: number; value: number }[];

  if (!snake.error) {
    snakeTs = formatCumulativeReturnSnake(
      snake.data.result.data,
      insight.metadata.last_value_date ?? insight.created_at
    );
  }

  return {
    validationError: false,
    data: {
      insight,
      predictions,
      entity,
      snakeTs,
    },
  };
};
