import {
  CreateScreenResponse,
  ExploreFiltersInsights,
  FilterInsightsSchemas,
  insight,
  ScreenerFilter,
  SortDirection,
  SortingColumn,
  WatchList,
  watchlist,
} from '@toggle/toggle';

import { mapToFilterOptions } from '~/components/filters/components/utils';
import { ActiveFilterOptions } from '~/components/filters/Filters';
import { SCREENER_ID, WATCHLIST_ID } from '~/config/constants';
import {
  ExploreFilterGroupKeys,
  FilterGroups,
  FilterState,
  SharedFilterState,
} from '~/hooks/use-filter-actions/useFilterActions';
import { create } from '~/stores/create-store/createStore';
import { Watchlist } from '~/stores/use-watchlist/watchlist-types';
import { apiFetch } from '~/utils/api-fetch/apiFetch';

import { getFilteredAssetIndices } from '../../components/explore-filters/asset-filter/utils';
import { extractByCondition } from '../../components/explore-filters/insight-filters-section/utils';
import { insightsAssetFilter } from '../../utils/filter-utils/filter-utils';
import {
  DIRECTION,
  INSIGHTS_QUALITY,
  PERIOD,
  RETURN_PROFILE,
  TRADING_HORIZON,
} from '../../utils/utils';

const EXPLORE_SEARCH_RESULTS_LIMIT = 30;

export const INSIGHT_FILTERS = 'INSIGHT_FILTERS';
const ASSET_FILTERS = 'ASSET_FILTERS';

const defaultFilterGroups = {
  ASSET_FILTERS: {
    activeFilterOptions: [],
    activeFilters: [],
    allFilters: [],
  },
  INSIGHT_FILTERS: {
    activeFilterOptions: [],
    activeFilters: [],
    allFilters: [],
  },
};

interface ExploreFilter extends ScreenerFilter {
  isDisabled?: boolean;
}

type FilterGroup = {
  group: ExploreFilterGroupKeys;
  schemas: ExploreFilter[];
};

type ExploreStore = {
  initialized: boolean;
  error: boolean;
  filteredOnly: boolean;
  exploreSearchResultsLimit: number;
  initStore: (
    savedWatchlists: Watchlist[],
    savedScreens: CreateScreenResponse[]
  ) => Promise<void>;
  clearAllActiveFilterOptions: () => void;
  resetFiltersByGroup: (filterKeys: string[]) => void;
  toggleInsightFilterDisabled: (isDisabled: boolean) => void;
  getIsCombined: () => boolean;
  setFilteredOnly: (value: boolean) => void;
  getShouldShowToggleFiltered: () => boolean;
  sortColumn?: string;
  sortDirection?: SortDirection;
  setSortColumn: (column: string, direction: SortDirection) => void;
  clearSort: () => void;
  hasCustomDefaultAssetFilter?: boolean;
  setHasCustomDefaultAssetFilter: (value?: boolean) => void;
} & SharedFilterState<ExploreFilterGroupKeys>;

export const useExploreStore = create<ExploreStore>((set, get) => ({
  setHasCustomDefaultAssetFilter: value =>
    set({ hasCustomDefaultAssetFilter: value }),
  initStore: async (savedWatchlists, savedScreens) => {
    const insightFiltersRes = await apiFetch<FilterInsightsSchemas>(
      insight.getFilters.path
    );
    const publicLists = await apiFetch<WatchList[]>(
      watchlist.getPublicLists.path()
    );
    const filteredPublicLists = getFilteredAssetIndices({
      data: publicLists.data,
    });

    if (insightFiltersRes.error) {
      set({
        initialized: true,
        error: true,
        filterGroups: defaultFilterGroups,
      });
      return;
    }

    const { groups: resGroups } = insightFiltersRes.data;
    const periodSchema =
      resGroups?.find(g => g.group === 'none')?.schemas ?? [];

    const assetScreenerFilters = [
      {
        group: ASSET_FILTERS,
        schemas: [insightsAssetFilter],
      },
    ];

    const insightFilters = resGroups[1]?.schemas;
    const sortedInsightFilter = [
      DIRECTION,
      RETURN_PROFILE,
      TRADING_HORIZON,
      INSIGHTS_QUALITY,
    ].reduce<ExploreFiltersInsights>((accu, curr) => {
      const filter = insightFilters?.find(filter => filter.key === curr);

      if (filter) {
        accu.push(filter);
      }

      return accu;
    }, []);

    const insightScreenerFilters = [
      {
        group: INSIGHT_FILTERS,
        schemas: [...periodSchema, ...sortedInsightFilter],
      },
    ];

    const groups = [
      ...assetScreenerFilters,
      ...insightScreenerFilters,
    ] as FilterGroup[];

    const { hasCustomDefaultAssetFilter, filterGroups: existingFilterGroups } =
      get();
    const filterGroups = groups.reduce((acc, group) => {
      const activeFilterOptions: ActiveFilterOptions[] = [];

      if (group.group === INSIGHT_FILTERS) {
        const oneMonthConfigOption = group.schemas?.[0]?.config?.options?.find(
          option => option.key === '1m'
        );

        if (oneMonthConfigOption) {
          activeFilterOptions.push({
            id: group.schemas[0].key,
            filter: group.schemas[0].key,
            options: [oneMonthConfigOption],
          });
        }
      }

      if (group.group === ASSET_FILTERS) {
        if (hasCustomDefaultAssetFilter) {
          activeFilterOptions.push(
            ...existingFilterGroups.ASSET_FILTERS.activeFilterOptions
          );
        } else {
          const watchlistFilterConfigOptions = mapToFilterOptions({
            items: savedWatchlists,
            valueKey: WATCHLIST_ID,
          });
          const screenFilterConfigOptions = mapToFilterOptions({
            items: savedScreens,
            valueKey: SCREENER_ID,
          });
          const firstWatchlistOption = watchlistFilterConfigOptions[0];
          const firstScreenOption = screenFilterConfigOptions[0];

          if (firstWatchlistOption) {
            activeFilterOptions.push({
              id: group.schemas[0].key,
              filter: group.schemas[0].key,
              options: [firstWatchlistOption],
            });
          } else if (firstScreenOption) {
            activeFilterOptions.push({
              id: group.schemas[0].key,
              filter: group.schemas[0].key,
              options: [firstScreenOption],
            });
          } else {
            const listFilterConfigOptions = mapToFilterOptions({
              items: filteredPublicLists,
              valueKey: 'indexId',
            });
            activeFilterOptions.push({
              id: group.schemas[0].key,
              filter: group.schemas[0].key,
              options: [listFilterConfigOptions[0]],
            });
          }
        }
      }

      const filterState: FilterState = {
        allFilters: group.schemas,
        activeFilters: group.schemas.filter(f => f.visible),
        activeFilterOptions,
      };

      acc[group.group] = filterState;
      return acc;
    }, {} as FilterGroups<ExploreFilterGroupKeys>);

    set({
      filterGroups,
      initialized: true,
      error: false,
      sortColumn: SortingColumn.Entity,
      sortDirection: SortDirection.Ascending,
      hasCustomDefaultAssetFilter: false,
    });
  },
  exploreSearchResultsLimit: EXPLORE_SEARCH_RESULTS_LIMIT,
  filterGroups: defaultFilterGroups,
  filteredOnly: true,
  initialized: false,
  error: false,
  toggleInsightFilterDisabled: (isDisabled: boolean) => {
    const insightFilters = get().filterGroups[INSIGHT_FILTERS];
    const activeFilters = insightFilters.activeFilters.map(filter => {
      if (filter.key !== PERIOD) {
        return {
          ...filter,
          isDisabled,
        };
      }

      return filter;
    });

    set(state => {
      return {
        ...state,
        filterGroups: {
          ...state.filterGroups,
          INSIGHT_FILTERS: {
            ...insightFilters,
            activeFilters,
          },
        },
      };
    });
  },
  resetFiltersByGroup: (filterKeys: string[]) => {
    set(state => {
      const clearedFilterGroups = { ...state.filterGroups };

      filterKeys.forEach(key => {
        const filters = state.filterGroups[key as ExploreFilterGroupKeys];
        const activeFilters = filters.activeFilterOptions.filter(
          filter => filter.id === PERIOD
        );

        clearedFilterGroups[key as ExploreFilterGroupKeys] = {
          ...filters,
          activeFilterOptions: activeFilters,
        };
      });

      return { filterGroups: clearedFilterGroups };
    });
  },
  clearAllActiveFilterOptions: () =>
    set(state => {
      const clearedFilterGroups = {
        ASSET_FILTERS: {
          ...state.filterGroups.ASSET_FILTERS,
          activeFilterOptions: [],
        },
        INSIGHT_FILTERS: {
          ...state.filterGroups.INSIGHT_FILTERS,
          activeFilterOptions: [],
        },
      };

      return { filterGroups: clearedFilterGroups };
    }),
  getIsCombined: () => {
    const { getShouldShowToggleFiltered, filteredOnly } = get();
    const showToggle = getShouldShowToggleFiltered();
    return !showToggle || (showToggle && !filteredOnly);
  },
  setFilteredOnly: (value: boolean) => set({ filteredOnly: value }),
  getShouldShowToggleFiltered: () => {
    const { nonMatching } = extractByCondition(
      get().filterGroups.INSIGHT_FILTERS.activeFilterOptions,
      item => item.id === PERIOD
    );
    return !!nonMatching.length;
  },
  setSortColumn: (column: string, direction: SortDirection) => {
    set({
      sortColumn: column,
      sortDirection: direction,
    });
  },
  clearSort: () => {
    set({
      sortColumn: undefined,
      sortDirection: undefined,
    });
  },
}));
