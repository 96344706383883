import { Button } from '@toggle/design-system';
import styled from 'styled-components';

export const VerticalDivider = styled.div<{ $marginLeft: number }>`
  width: 1px;
  height: 16px;
  background-color: var(--border-soft);
  margin-left: ${props => `${props.$marginLeft}px`};
  margin-right: 4px;
`;

export const StyledButton = styled(Button)`
  padding-left: 8px;
  padding-right: 8px;
`;

export const DividerAndButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0px;
  align-items: center;
`;
