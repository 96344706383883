import React from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './AddAssets.styles';

type AddAssetsProps = {
  marginLeft?: number;
  onClick: () => void;
  dataTestId?: string;
};

export const AddAssets = ({
  marginLeft = 4,
  onClick,
  dataTestId = 'add-assets-button',
}: AddAssetsProps) => {
  const { t } = useTranslation('widget');
  return (
    <S.DividerAndButtonGroup>
      <S.VerticalDivider $marginLeft={marginLeft} />
      <S.StyledButton
        variant="empty"
        size="xsmall"
        iconName="Add"
        label={t('widget:sendAllAssetsTo.add')}
        onClick={onClick}
        data-testid={dataTestId}
      />
    </S.DividerAndButtonGroup>
  );
};
