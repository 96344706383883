import { AlertType } from '@toggle/design-system';
import { RangeHorizon } from '@toggle/helpers';
import { ScenariosCondition, ScenariosRequest } from '@toggle/toggle';
import isEqual from 'lodash/isEqual';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useFilterActions } from '~/hooks/use-filter-actions/useFilterActions';
import {
  ScenariosData,
  useScenarios,
} from '~/hooks/use-scenarios/useScenarios';
import { appPaths } from '~/routes/app-paths';
import { ScenarioDrawer } from '~/widgets/scenario/components/scenario-drawer/ScenarioDrawer';

import { useScenarioState } from '../../hooks/use-scenario-state/useScenarioState';
import { useScenarioEntityFilters } from '../../hooks/use-scenario-store/filter-utils';
import { useScenarioStore } from '../../hooks/use-scenario-store/useScenarioStore';
import { getColumnFilters } from '../../utils/scenario-column-filters/scenario-column-filters';
import {
  checkIfFirstOptionIsNotChild,
  mapAssistantConditionsToConditions,
  mapFiltersToConditions,
} from '../../utils/scenario-conditions/scenario-conditions';
import { getConditionFilters } from '../../utils/scenario-conditions-filters/scenario-conditions-filters';
import { ScenarioTableData } from '../../utils/scenario-table-utils/scenario-table-utils';
import { ColumnFilters } from '../column-filters/ColumnFilters';
import { useScenarioAssistant } from '../scenario-assistant-modal/use-scenario-assistant/useScenarioAssistant';
import { ScenarioFilters } from '../scenario-filters/ScenarioFilters';
import { ScenarioResults } from '../scenario-results/ScenarioResults';
import { ScenarioToolbar } from '../scenario-toolbar/ScenarioToolbar';
import * as S from './ScenarioConditions.styles';

export interface ScenarioConditionsProps {
  entitiesTags?: string[];
  onEditAssets: () => void;
  noAssetsButtonLabel?: string;
  onAddConditions?: () => void;
  onEditAssistantCondition?: (filterKey: string) => void;
  filtersChildren?: ReactNode;
}

const HORIZONS: string[] = [
  RangeHorizon.OneWeek,
  RangeHorizon.TwoWeeks,
  RangeHorizon.OneMonth,
  RangeHorizon.ThreeMonths,
  RangeHorizon.SixMonths,
];

// eslint-disable-next-line max-lines-per-function
export const ScenarioConditions = ({
  entitiesTags = [],
  noAssetsButtonLabel,
  onEditAssets,
  onAddConditions,
  onEditAssistantCondition,
  filtersChildren,
}: ScenarioConditionsProps) => {
  const { t } = useTranslation(['scenario', 'analyze', 'widget', 'screener']);
  const navigate = useNavigate();

  const [selectedData, setSelectedData] = useState<ScenariosData>();

  const horizonLabels = [t('scenario:summary'), ...HORIZONS];
  const [activeHorizon, setActiveHorizon] = useState<string>(horizonLabels[0]);
  const {
    conditions: assistantConditions,
    runConfirmedConditions,
    resetAskedQuestions,
  } = useScenarioAssistant(s => ({
    conditions: s.conditions.filter(c => c.active),
    runConfirmedConditions: s.runConfirmedConditions,
    resetConditions: s.resetConditions,
    resetAskedQuestions: s.resetAskedQuestions,
  }));

  const isSummary = !HORIZONS.includes(activeHorizon);

  const {
    filterGroups,
    initStore,
    lastEntitiesTags,
    setLastEntitiesTags,
    lastConditions,
    setLastConditions,
    lastScenariosData,
    lastActiveFilterOptions,
    setLastActiveFilterOptions,
    hasExceededUsageLimit,
  } = useScenarioStore(state => ({
    filterGroups: state.filterGroups,
    initStore: state.initStore,
    lastEntitiesTags: state.lastEntitiesTags,
    setLastEntitiesTags: state.setLastEntitiesTags,
    lastConditions: state.lastConditions,
    setLastConditions: state.setLastConditions,
    lastScenariosData: state.lastScenariosData,
    lastActiveFilterOptions: state.lastActiveFilterOptions,
    setLastActiveFilterOptions: state.setLastActiveFilterOptions,
    hasExceededUsageLimit: state.hasExceededUsageLimit,
  }));

  const {
    activeFilterOptions: activeScenarioConditionsFilterOptions,
    activeFilters: activeScenarioConditionsFilters,
  } = filterGroups.SCENARIO_CONDITIONS;

  const {
    activeFilters: activeScenarioAssetFilters,
    activeFilterOptions: activeScenarioAssetFilterOptions,
  } = filterGroups.ASSET_FILTERS;

  const { resetActiveFilterOptions: resetActiveConditionsFilterOptions } =
    useFilterActions({
      store: useScenarioStore,
      group: 'SCENARIO_CONDITIONS',
    });

  useEffect(() => {
    const hasActiveFilterOptionsChanged = !isEqual(
      activeScenarioAssetFilterOptions,
      lastActiveFilterOptions
    );

    if (hasActiveFilterOptionsChanged) {
      resetActiveConditionsFilterOptions();
    }
  }, []);

  useEffect(() => {
    return () => {
      setLastActiveFilterOptions(activeScenarioAssetFilterOptions);
    };
  }, [activeScenarioAssetFilterOptions]);

  const { isLoading, error, data, refetch, abort, sendMessage } =
    useScenarios();
  const scenarioEntityFilters = useScenarioEntityFilters();

  useEffect(() => {
    if (
      !activeScenarioConditionsFilters.length &&
      !activeScenarioAssetFilters.length
    ) {
      initStore([
        {
          group: 'SCENARIO_CONDITIONS',
          schemas: getConditionFilters(t),
        },
        {
          group: 'COLUMN_FILTERS',
          schemas: getColumnFilters(t),
        },
        {
          group: 'ASSET_FILTERS',
          schemas: scenarioEntityFilters,
        },
      ]);
    }
  }, []);

  useEffect(() => {
    setActiveHorizon(horizonLabels[0]);
  }, [data]);

  const onAbort = () => {
    abort();
    setLastConditions(conditions.slice(0, -1));
  };

  const activeFilterOptionsFiltered =
    activeScenarioConditionsFilterOptions.filter(filterOption =>
      checkIfFirstOptionIsNotChild(filterOption.options)
    );

  const conditions: ScenariosCondition[] = useMemo(
    () => [
      ...mapFiltersToConditions(activeFilterOptionsFiltered),
      ...mapAssistantConditionsToConditions(assistantConditions),
    ],
    [activeFilterOptionsFiltered, assistantConditions]
  );

  const { entitiesOrConditionsChanged } = useScenarioState({
    conditions,
    lastConditions,
    entitiesTags,
    lastEntitiesTags,
  });

  const scenarioResultsData = entitiesOrConditionsChanged
    ? data
    : lastScenariosData;

  const onRunConditions = () => {
    const requestPayload: ScenariosRequest = {
      entities: entitiesTags.map(tag => ({
        tag,
      })),
      conditions,
      horizons: HORIZONS,
      start_horizon: RangeHorizon.ZeroDay,
      end_horizon: RangeHorizon.SixMonths,
      skip_report: false,
    };

    setLastConditions(conditions);
    setLastEntitiesTags(entitiesTags);
    sendMessage(requestPayload);
    runConfirmedConditions();
  };

  const onRowClick = ({ entity }: ScenarioTableData) => {
    const selectedData = scenarioResultsData.find(
      item => item.entity.tag === entity.tag
    );

    if (selectedData) {
      setSelectedData(selectedData);
    }
  };

  const hasData = scenarioResultsData.some(item => item.predictions.length > 0);

  return (
    <>
      {hasExceededUsageLimit && (
        <S.StyledAlert
          variant="inline"
          type={AlertType.Warning}
          title={t('scenario:warning.dailyLimitReached')}
          message={t('scenario:warning.yourPlanHasALimit')}
          primaryBtn={{
            variant: 'primary',
            onClick: () => {
              navigate(appPaths.settings.subscription);
            },
            label: t('scenario:buttonLabels.upgrade'),
            size: 'large',
          }}
        />
      )}
      <ScenarioFilters
        filtersChildren={filtersChildren}
        onEditAssistantCondition={onEditAssistantCondition}
        resetAskedQuestions={resetAskedQuestions}
      />
      <ColumnFilters />
      <ScenarioToolbar
        activeHorizon={activeHorizon}
        entitiesTags={entitiesTags}
        horizonLabels={horizonLabels}
        setActiveHorizon={setActiveHorizon}
        hasData={hasData}
      />

      <ScenarioResults
        activeHorizon={activeHorizon}
        disabled={entitiesOrConditionsChanged}
        data={scenarioResultsData}
        entitiesTags={entitiesTags}
        noAssetsButtonLabel={
          noAssetsButtonLabel ?? t('scenario:buttonLabels.goBackButtonLabel')
        }
        hasError={error}
        horizons={HORIZONS}
        isLoading={isLoading}
        isSummary={isSummary}
        conditionsCount={conditions.length}
        lastConditions={lastConditions}
        onAbort={onAbort}
        onEditAssets={onEditAssets}
        onRowClick={onRowClick}
        refetch={refetch}
        onRunConditions={onRunConditions}
        onAddConditions={onAddConditions}
      />

      {selectedData?.entity && (
        <ScenarioDrawer
          activeHorizon={activeHorizon}
          closeDrawer={() => {
            setSelectedData(undefined);
          }}
          horizons={HORIZONS}
          isSummary={isSummary}
          lastConditions={lastConditions}
          scenario={selectedData}
        />
      )}
    </>
  );
};
