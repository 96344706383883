import React from 'react';
import { useTranslation } from 'react-i18next';

import * as StylesFilter from '~/components/filter/Filter.styles';

interface FilterResetButtonProps {
  handleReset: (e: Event) => void;
}

export const FilterResetButton = ({ handleReset }: FilterResetButtonProps) => {
  const { t } = useTranslation(['common']);
  return (
    <StylesFilter.ResetItem onSelect={handleReset}>
      <StylesFilter.ResetButton data-testid="reset-label">
        <StylesFilter.ResetIconWrapper>
          <StylesFilter.ResetIcon iconName="Reset" />
        </StylesFilter.ResetIconWrapper>
        <StylesFilter.ResetButtonLabel>
          {t('common:filters.reset')}
        </StylesFilter.ResetButtonLabel>
      </StylesFilter.ResetButton>
    </StylesFilter.ResetItem>
  );
};
