import { isEntity } from '@toggle/helpers';
import { EntitySearchType } from '@toggle/toggle';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router';

import { assetSubClassCategories as categories } from '~/components/search-modal/assetSubClassCategories';
import { Chips } from '~/components/search-modal/components/chips/Chips';
import { ListItem } from '~/components/search-modal/components/list-item/ListItem';
import {
  addTitleAndSearchType,
  EntityOrThemeWithListTypes,
  isThemeItem,
  ListItemData,
} from '~/components/search-modal/utils';
import { SearchModalBase } from '~/components/search-modal-base/SearchModalBase';
import { useNavigateToAssetOverview } from '~/hooks/use-navigate-to-asset-overview/useNavigateToAssetOverview';
import { useTagsSearchCriteria } from '~/hooks/use-tags-search/use-tags-search-criteria';
import { useTagsSearch } from '~/hooks/use-tags-search/useTagsSearch';
import { appPaths } from '~/routes/app-paths';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { useRecentList } from '~/views/turbo-chart/hooks/use-recent-list/useRecentList';

import { useThemeSearch } from './hooks/use-theme-search/useThemeSearch';
import * as S from './SearchAssetWidget.styles';

//Hide economics pill
const assetSubClassCategories = categories.filter(c => c.value !== 'economics');

export const SearchAssetWidget = () => {
  const { t } = useTranslation('widget');
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeChip, setActiveChip] = useState('');
  const [focusedText, setFocusedText] = useState<string>();
  const isBasicUser = useSubscription(state => state.isBasicUser());

  const { themeSearchResults, handleTextChange } = useThemeSearch();

  const { navigateToPage } = useNavigateToAssetOverview();
  const navigate = useNavigate();
  const { recentlyOpenedThemesOrEntities } = useRecentList();
  const filteredRecentlyOpenedThemesOrEntities =
    recentlyOpenedThemesOrEntities.filter(themeOrEntityItem => {
      const isEntityItem = isEntity(themeOrEntityItem);

      if (isEntityItem && isBasicUser) {
        return !themeOrEntityItem.gated;
      } else {
        return true;
      }
    });

  const { criteria } = useTagsSearchCriteria({
    activeSubclassChip: activeChip,
  });
  const {
    tags: entitySearchResults,
    loading,
    query: searchQuery,
    searchTags,
    setQuery,
  } = useTagsSearch({
    searchType: activeChip
      ? EntitySearchType.EntityLookup
      : EntitySearchType.EntityAndEconomies,
    params: {
      limit: 30,
      ...criteria,
    },
  });

  const showEntityResults = !!entitySearchResults.length;
  const showThemeResults = !!themeSearchResults.length;
  const isThemeChipActive = activeChip === 'themes';
  const entitySearchResultsIsVisible =
    showEntityResults && (!isThemeChipActive || !!searchQuery);
  const themeSearchResultsIsVisible =
    showThemeResults && (isThemeChipActive || !!searchQuery);
  const entitiesTitle =
    !activeChip && entitySearchResultsIsVisible
      ? t('widget:search.assets')
      : '';
  const themesTitle =
    !activeChip && themeSearchResultsIsVisible
      ? t('widget:search.investmentThemes')
      : '';

  const noSearchResultsLabel = t('widget:search.noSearchResults');

  const searchListData: ListItemData[] = useMemo(() => {
    return [
      {
        data: filteredRecentlyOpenedThemesOrEntities,
        searchListType: 'recent',
        isVisible:
          !searchQuery &&
          !activeChip &&
          !!filteredRecentlyOpenedThemesOrEntities.length,
      },
      {
        title: entitiesTitle,
        data: entitySearchResults,
        searchListType: 'result',
        isVisible: entitySearchResultsIsVisible,
      },
      {
        title: themesTitle,
        data: themeSearchResults,
        searchListType: 'result',
        isVisible: themeSearchResultsIsVisible,
        isCollapsibleSection: entitySearchResultsIsVisible,
      },
    ];
  }, [
    showEntityResults,
    filteredRecentlyOpenedThemesOrEntities,
    searchQuery,
    entitySearchResults,
    activeChip,
    showThemeResults,
    themeSearchResults,
  ]);

  const filteredData = useMemo(
    () =>
      searchListData
        .filter(e => e.isVisible)
        .flatMap(addTitleAndSearchType) as EntityOrThemeWithListTypes[],
    [searchListData]
  );

  useEffect(() => {
    setActiveChip(
      filteredRecentlyOpenedThemesOrEntities.length
        ? ''
        : assetSubClassCategories[1].value
    );
  }, [filteredRecentlyOpenedThemesOrEntities.length]);

  const onClose = () => {
    searchParams.delete('widget');
    setSearchParams(searchParams);
  };

  const handleSelectItem = (item: EntityOrThemeWithListTypes) => {
    const isTheme = isThemeItem(item);
    isTheme
      ? navigate(`${appPaths.themeOverview}/${item.id}`)
      : navigateToPage({ entityTag: item.tag, searchParams });
  };

  const handleSearch = (value: string) => {
    searchTags(value);
    handleTextChange(value);
    setActiveChip('');
    setFocusedText(undefined);
  };

  const handleChangeChip = (selectedChip: string) => {
    if (selectedChip === activeChip) {
      setActiveChip('');
    } else {
      setActiveChip(selectedChip);
    }

    setFocusedText('');
    setQuery('');
  };

  const getSearchAssetWidgetChipData = () => {
    const startIdx = filteredRecentlyOpenedThemesOrEntities.length ? 0 : 1;
    return assetSubClassCategories.slice(startIdx);
  };

  const chipData = getSearchAssetWidgetChipData();

  return (
    <SearchModalBase<EntityOrThemeWithListTypes>
      searchValue={focusedText ?? searchQuery}
      value={searchQuery}
      handleSearch={handleSearch}
      selectItem={handleSelectItem}
      dropdownItems={filteredData}
      data-testid="search-asset-modal-widget"
      isOpen
      isLoading={loading}
      shouldResetScrollOnItemsChange={false}
      title={t('widget:search.dataSearchTitle')}
      onClose={onClose}
      renderDropdownItem={(item, _, isFocused) => (
        <ListItem
          item={item}
          isFocused={isFocused}
          setFocusedText={setFocusedText}
          searchQuery={searchQuery}
          variant="secondary"
        />
      )}
      beforeResultsNode={
        !searchQuery && (
          <S.ChipsWrapper>
            <Chips
              chipData={chipData}
              activeChip={activeChip}
              handleChangeChip={handleChangeChip}
            />
          </S.ChipsWrapper>
        )
      }
      placeholder={t('widget:search.placeholder')}
      noSearchResultsLabel={noSearchResultsLabel}
    />
  );
};
