import { Icon } from '@toggle/design-system';
import React from 'react';

import * as StylesFilter from '~/components/filter/Filter.styles';
import { Option } from '~/components/filter/Option';
import { AssetsFilterOption } from '~/components/filters/components/utils';
import { ToggleFilterProps } from '~/components/update-filter-options/updateFilterOptions';

interface AssetsOptionsListProps {
  filterKey: string;
  options: AssetsFilterOption[];
  isChecked: (key: string) => boolean;
  onFilterSelect: (props: ToggleFilterProps, active: boolean) => void;
}

export const AssetsOptionsList = ({
  options,
  isChecked,
  onFilterSelect,
  filterKey,
}: AssetsOptionsListProps) => {
  return (
    <StylesFilter.ScrollableList>
      <StylesFilter.Separator />
      {options.map((option: AssetsFilterOption, idx: number) => {
        const { customHeaderTitle, hasBottomBorder, key } = option;
        const checked = isChecked(key);

        return (
          <div key={option.key || `cta-option-${idx}`}>
            {customHeaderTitle && (
              <StylesFilter.CustomHeaderLabel>
                {customHeaderTitle}
              </StylesFilter.CustomHeaderLabel>
            )}
            {option.key ? (
              <Option
                option={option}
                isChecked={checked}
                handleClick={() =>
                  onFilterSelect(
                    {
                      option,
                      filterKey: filterKey,
                      filterId: filterKey,
                    },
                    checked
                  )
                }
                multiselect={false}
              />
            ) : (
              <StylesFilter.Item
                data-testid="custom-option-item"
                onClick={option.customOptionCallback}
              >
                <StylesFilter.MenuItemLeftIndicator />
                <StylesFilter.ItemText>
                  <StylesFilter.CustomLabel>
                    <Icon iconName="Add" size={14} />
                    <span>{option.customOptionLabel}</span>
                  </StylesFilter.CustomLabel>
                </StylesFilter.ItemText>
              </StylesFilter.Item>
            )}
            {hasBottomBorder && <StylesFilter.CustomBottomBorder />}
          </div>
        );
      })}
    </StylesFilter.ScrollableList>
  );
};
