import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { ChatInputConfig } from '~/components/chat';
import {
  EntityOrThemeWithListTypes,
  isThemeItem,
} from '~/components/search-modal/utils';
import { isPathMatches } from '~/components/sidebar/utils/navigation-utils';
import { Source } from '~/stores/use-chat/use-chat.types';
import { useChat } from '~/stores/use-chat/useChat';
import { CITATION_GROUP_ID } from '~/views/intel/document-sources-view/DocumentSourcesView';

import { useNavigateToAssetOverview } from '../use-navigate-to-asset-overview/useNavigateToAssetOverview';
import { useNavigateToThemeOverview } from '../use-navigate-to-theme-overview/useNavigateToThemeOverview';

interface UseChatInputHandlersProps {
  config?: ChatInputConfig;
  chatContext?: {
    target: Source;
    documents?: string[];
    documentTypes?: string[];
    entityTags?: string[];
  };
  submitCallback?: (message: string) => void;
  isLoading: boolean;
}

export const useChatInputHandlers = ({
  config,
  chatContext,
  submitCallback,
  isLoading,
}: UseChatInputHandlersProps) => {
  const { t } = useTranslation('chat');
  const [params, setParams] = useSearchParams();
  const { navigateToChatMessage: navigateToAssetOverviewChatMessage } =
    useNavigateToAssetOverview();
  const { navigateToChatMessage: navigateToThemeOverviewChatMessage } =
    useNavigateToThemeOverview();
  const {
    resetStore,
    abortRequest,
    resetSession,
    clearDocumentContextMessages,
    sendMessage,
    resetIsSearchWithinContext,
  } = useChat(state => ({
    resetStore: state.resetStore,
    abortRequest: state.abortRequest,
    resetSession: state.resetSession,
    clearDocumentContextMessages: state.clearDocumentContextMessages,
    sendMessage: state.sendMessage,
    resetIsSearchWithinContext: state.resetIsSearchWithinContext,
  }));

  const shouldResetStore = (question: string) => {
    if (!isPathMatches(['alfred'])) {
      return true;
    }

    const restRegex = new RegExp(`^${t('resetSession')}$`, 'i');
    return restRegex.test(question);
  };

  const closeDocumentSourcesModal = () => {
    if (params.get(CITATION_GROUP_ID)) {
      const newParams = new URLSearchParams(params);
      newParams.delete(CITATION_GROUP_ID);
      setParams(newParams);
    }
  };

  const submitHandler = (
    inputValue: string,
    onSubmitCallbackHandler: () => void
  ) => {
    if (isLoading || !inputValue.trim()) {
      return;
    }

    const question = inputValue.trim();

    if (config?.clearDocumentContextOnSubmit) {
      resetSession(true);
      clearDocumentContextMessages();
    }

    // reset store if asking question not from alfred
    // in other words, init new session
    if (shouldResetStore(question)) {
      resetStore();
    }

    sendMessage({
      question,
      ...(chatContext?.target && {
        target: chatContext.target,
        ancillary: {
          documents: chatContext?.documents,
          document_types: chatContext?.documentTypes,
          entity_tags: chatContext?.entityTags,
        },
      }),
    });

    onSubmitCallbackHandler();
    resetIsSearchWithinContext();
    closeDocumentSourcesModal();
    submitCallback?.(question);
  };

  const onSelect = (item: EntityOrThemeWithListTypes, clear: () => void) => {
    const isTheme = isThemeItem(item);

    if (isTheme) {
      navigateToThemeOverviewChatMessage(item.id, item.name);
    } else {
      navigateToAssetOverviewChatMessage(item.tag);
    }

    clear();
  };

  const onKeyDown = (
    _: React.KeyboardEvent<HTMLElement>,
    inputText: string,
    onSubmitCallbackHandler: () => void
  ) => {
    submitHandler(inputText, onSubmitCallbackHandler);
  };

  const onSubmit = (
    event: React.FormEvent<HTMLFormElement>,
    inputText: string,
    onSubmitCallbackHandler: () => void
  ) => {
    event.preventDefault();
    submitHandler(inputText, onSubmitCallbackHandler);
  };

  return {
    onSubmit,
    onKeyDown,
    onSelect,
    onAbort: abortRequest,
  };
};
