import { TabLines } from '@toggle/design-system';
import { AssetSubClassCode, Entity, isEtf, SnakeMeta } from '@toggle/toggle';
import React, { RefObject, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCondensedHeader } from '~/hooks/use-condensed-header/useCondensedHeader';
import { useKnowledgeGraph } from '~/hooks/use-knowledge-graph/useKnowledgeGraph';
import { TabsType } from '~/modules/tab-content/types';
import { getTabs } from '~/modules/tab-content/utils';

import { EntityHeader } from '../entity-header/EntityHeader';
import { ChartPrice } from '../simple-chart/components/chart-price/ChartPrice';
import * as S from './AssetOverviewHeader.styles';

export interface AssetOverviewHeaderProps {
  snakeMeta?: SnakeMeta;
  entity: Entity;
  activeTab: TabsType;
  changeTab: (index: number, tabKey: TabsType) => void;
  scrollContainerRef: RefObject<HTMLDivElement | null>;
  overviewTabChartContainerRef: RefObject<HTMLDivElement | null>;
  showShadow: boolean;
  documentsCount?: number;
}

export const AssetOverviewHeader = ({
  snakeMeta,
  entity,
  activeTab,
  changeTab,
  scrollContainerRef,
  overviewTabChartContainerRef,
  showShadow,
  documentsCount = 0,
}: AssetOverviewHeaderProps) => {
  const { t } = useTranslation([
    'chart',
    'analyze',
    'countries',
    'asset-overview',
  ]);
  const [isScrolledEnough, setIsScrolledEnough] = useState(false);

  useCondensedHeader({
    getRootElement: () => scrollContainerRef.current,
    getTargetElement: () => overviewTabChartContainerRef.current,
    toggleCondensed: setIsScrolledEnough,
    deps: [scrollContainerRef.current, overviewTabChartContainerRef.current],
  });

  const isCondensed = activeTab !== TabsType.Overview || isScrolledEnough;
  const { isError } = useKnowledgeGraph(entity.tag, isEtf(entity));

  const isStock = entity.sub_class === AssetSubClassCode.EquityStock;
  const tabs = getTabs(t, { isStock, documentsCount });
  const filteredTabs = tabs
    .filter(tab => !tab.isHidden)
    .filter(tab => !isError || tab.tabKey !== TabsType.KnowledgeGraph);

  return (
    <S.Header $showShadow={showShadow}>
      <S.Layout>
        <S.HeaderContent $hasEntity={!!entity} $isCondensed={isCondensed}>
          {entity && (
            <>
              <S.ContentWrapper $isCollapsed={isCondensed}>
                <EntityHeader
                  entity={entity}
                  priceDisplayNode={
                    <ChartPrice
                      entity={entity}
                      t={t}
                      snakeMeta={snakeMeta}
                      isCondensed={isCondensed}
                      enablePriceHover={false}
                    />
                  }
                  isCondensed={isCondensed}
                />
              </S.ContentWrapper>
              <TabLines
                tabs={filteredTabs}
                activeTab={activeTab}
                onChange={changeTab}
              />
            </>
          )}
        </S.HeaderContent>
      </S.Layout>
    </S.Header>
  );
};
