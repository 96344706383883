import { useSearchParams } from 'react-router-dom';

import { queryKeys } from '~/routes/app-paths';
import { useChat } from '~/views/reasoner/use-chat/useChat';

export type ChatMode = 'intel' | 'reasoner';

export const DEEP_RESEARCH_QUERY_PARAM = 'research';

export const useChatMode = () => {
  const [params, setSearchParams] = useSearchParams();

  const setMode = (mode: ChatMode) => {
    useChat.setState({ chatMode: mode });
    setSearchParams(params => {
      if (mode === 'reasoner') {
        params.set(queryKeys.mode, DEEP_RESEARCH_QUERY_PARAM);
      } else {
        params.delete(queryKeys.mode);
      }

      return params;
    });
  };

  const getMode = (): ChatMode => {
    const { chatMode } = useChat.getState();
    const isDeepResearchMode =
      params.get(queryKeys.mode) === DEEP_RESEARCH_QUERY_PARAM;
    const chatModeFromParams = isDeepResearchMode ? 'reasoner' : 'intel';

    if (!chatMode) {
      useChat.setState({ chatMode: chatModeFromParams });
      return chatModeFromParams;
    }

    return chatMode;
  };

  return {
    setMode,
    getMode,
  };
};
