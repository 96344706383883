import { SortingState } from '@tanstack/react-table';
import {
  CreateScreenResponse,
  FetchScreenResponse,
  FilterSchemas,
  screener,
  ScreenerFilter,
  ScreenerFilterGroupKeys,
  ScreenerFilters,
  TableState,
  UpdateScreenResponse,
} from '@toggle/toggle';
import isEqual from 'lodash/isEqual';

import { ActiveFilterOptions } from '~/components/filters/Filters';
import { WORKING_LIST_ID } from '~/config/constants';
import {
  FilterGroups,
  FilterState,
  SharedFilterState,
} from '~/hooks/use-filter-actions/useFilterActions';
import { create } from '~/stores/create-store/createStore';
import { useSubscription } from '~/stores/use-subscription/useSubscription';
import { apiFetch, ApiFetchResponse } from '~/utils/api-fetch/apiFetch';
import { NON_US_INDICES } from '~/views/insights/components/explore-filters/asset-filter/utils';
import { checkIfFirstOptionIsNotChild } from '~/widgets/scenario/utils/scenario-conditions/scenario-conditions';

import {
  ColumnMetaWithCategory,
  DEFAULT_TAB,
  SavedCategorySetting,
} from '../results/screener-table-utils';
import {
  ASSET_FILTERS_KEY,
  assetFilter,
  clearActiveFilterOptions,
  getFilterOptionsPerGroup,
  getSavedScreenFilterGroups,
  resetKGTableState,
} from './filter-utils';

export type ScreenerStore = {
  tableState: TableState;
  activeSavedScreen?: CreateScreenResponse;
  draftScreenFilterGroups?: FilterGroups<ScreenerFilterGroupKeys>;
  savedScreens: CreateScreenResponse[];
  handlePinnedColumns: (props: {
    columnsMeta: ColumnMetaWithCategory[];
    pin: boolean;
  }) => void;
  handleColumnOrdering: (props: { tab: string; fields: string[] }) => void;
  handleColumnSorting: (props: SortingState) => void;
  initStore: () => Promise<void>;
  setActiveSavedScreen: (screenerId: string | null, setDraft?: boolean) => void;
  hasFilterOptionsChanged: (group: ScreenerFilterGroupKeys) => boolean;
  hasTableChanges: (tab: string, defaultTabOrdering?: string[]) => boolean;
  unsavedChanges: () => boolean;
  unsavedDraftChanges: () => boolean;
  saveNewFilter: (
    name: string
  ) => Promise<ApiFetchResponse<CreateScreenResponse>>;
  updateSavedFilter: (
    id: string,
    name?: string
  ) => Promise<ApiFetchResponse<UpdateScreenResponse>>;
  deleteSavedFilter: (screenId: string) => Promise<ApiFetchResponse<void>>;
  resetActiveFilterOptions: (
    group?: ScreenerFilterGroupKeys,
    resetToDefault?: boolean
  ) => void;
  createDraftScreen: (props?: { forceNew?: boolean; init?: boolean }) => void;
  resetTableState: (tab: string) => void;
  canCreateNewScreen: () => boolean;
  shouldPromptUnsavedChanges: () => boolean;
  isWorkingListActiveInDraft: () => boolean;
  isWorkingListActive: () => boolean;
  customDefaultTableTab?: string;
  setCustomDefaultTableTab: (tab?: string) => void;
} & SharedFilterState;

export const getDefaultOption = (
  filters: ScreenerFilters
): ActiveFilterOptions => {
  const defaultFilter = filters.find(f => f.config.default) as ScreenerFilter;
  return {
    id: defaultFilter.key,
    filter: defaultFilter.key,
    options: [
      defaultFilter.config.options.find(
        i => i.key === defaultFilter.config.default
      ) ?? defaultFilter.config.options[0],
    ],
  };
};

const getDefaultFilters = (filters: ScreenerFilters) =>
  filters.filter(f => f.visible);

const defaultTableState = {
  sorting: [],
  pinnedColumns: {},
  columnOrdering: {},
};
// eslint-disable-next-line max-lines-per-function
export const useScreenerStore = create<ScreenerStore>((set, get) => ({
  setCustomDefaultTableTab: tab => {
    set({ customDefaultTableTab: tab });
  },
  filterGroups: {} as FilterGroups<ScreenerFilterGroupKeys>,
  tableState: defaultTableState,
  savedScreens: [],
  activeSavedScreen: undefined,
  initStore: async () => {
    const [resFilters, resSavedScreens] = await Promise.all([
      apiFetch<FilterSchemas>(screener.getFilters.path, { method: 'get' }),
      apiFetch<FetchScreenResponse>(screener.getSavedScreens.path, {
        method: 'get',
      }),
    ]);
    const isBasicUser = useSubscription.getState().isBasicUser();

    if (!resFilters.error) {
      const { groups } = resFilters.data;
      const filterGroups = groups.reduce((res, group) => {
        const isMarketFilterGroup = group.group === 'Market Filters';
        let groupSchemas = group.schemas;

        if (isMarketFilterGroup) {
          groupSchemas = [
            assetFilter,
            ...group.schemas.map(schema => {
              if (schema.key === 'market_index') {
                const marketIndexOptions = schema.config.options.filter(
                  option =>
                    isBasicUser ? !NON_US_INDICES.includes(option.name) : true
                );
                return {
                  ...schema,
                  config: {
                    ...schema.config,
                    options: marketIndexOptions,
                  },
                };
              }

              return schema;
            }),
          ];
        }

        const filterState: FilterState = {
          allFilters: groupSchemas,
          activeFilterOptions: [],
          activeFilters: getDefaultFilters(groupSchemas),
        };
        res[group.group] = filterState;
        return res;
      }, {} as FilterGroups);
      set({ filterGroups });
    }

    if (!resSavedScreens.error) {
      set({ savedScreens: resSavedScreens.data.screens });
    }
  },
  saveNewFilter: async name => {
    const { path, schema } = screener.saveNewFilter;
    const { filterGroups, tableState } = get();
    const filterOptionGroups = getFilterOptionsPerGroup(filterGroups);
    const res = await apiFetch<CreateScreenResponse>(path, {
      schema,
      method: 'post',
      body: {
        favorite: false,
        name,
        config: {
          tableState,
          filterOptionGroups,
          isDraft: false,
          version: 'v1',
        },
      },
    });

    if (!res.error) {
      set(state => {
        return {
          savedScreens: [...state.savedScreens, res.data],
          activeSavedScreen: res.data,
          tableState: res.data.config.tableState,
          draftScreenFilterGroups: undefined,
        };
      });
    }

    return res;
  },
  updateSavedFilter: async (id, name) => {
    const { path, schema } = screener.updateSavedFilter;
    const { filterGroups, tableState, activeSavedScreen } = get();
    const filterOptionGroups = getFilterOptionsPerGroup(filterGroups);

    const res = await apiFetch<UpdateScreenResponse>(path(id), {
      schema,
      method: 'put',
      body: {
        favorite: false,
        name: name ?? activeSavedScreen?.name,
        config: {
          tableState,
          filterOptionGroups,
          isDraft: false,
          version: 'v1',
        },
      },
    });

    if (!res.error) {
      set(state => ({
        savedScreens: state.savedScreens.map(filter =>
          filter.id === id ? res.data : filter
        ),
        activeSavedScreen: res.data,
      }));
    }

    return res;
  },
  deleteSavedFilter: async screenId => {
    const { path } = screener.deleteSavedFilter;
    const res = await apiFetch<void>(path(screenId), {
      method: 'delete',
    });

    if (!res.error) {
      set(state => ({
        savedScreens: state.savedScreens.filter(
          filter => filter.id !== screenId
        ),
      }));
    }

    return res;
  },
  setActiveSavedScreen: screenerId => {
    const {
      savedScreens,
      filterGroups,
      activeSavedScreen,
      draftScreenFilterGroups,
    } = get();

    //setting draft as current screen
    if (screenerId === null) {
      set({
        activeSavedScreen: undefined,
        filterGroups: draftScreenFilterGroups,
      });
      return;
    }

    if (activeSavedScreen?.id !== screenerId) {
      const screen = savedScreens.find(f => f.id === screenerId);

      if (screen) {
        const { tableState = defaultTableState } = screen.config;

        set({
          filterGroups: getSavedScreenFilterGroups(filterGroups, screen.config),
          tableState,
          activeSavedScreen: screen,
        });
      }
    }
  },
  hasTableChanges: (tab: string, defaultTabOrdering?: string[]) => {
    const { tableState, activeSavedScreen } = get();

    if (!activeSavedScreen) {
      if (
        tableState.pinnedColumns[tab]?.length ||
        tableState.sorting.some(sort => defaultTabOrdering?.includes(sort.id))
      ) {
        return true;
      }

      if (defaultTabOrdering && tableState.columnOrdering[tab]) {
        const columnsMatch = isEqual(
          defaultTabOrdering,
          tableState.columnOrdering[tab]
        );
        return !columnsMatch;
      }

      return false;
    }

    const savedTableState = activeSavedScreen?.config.tableState;

    const sortMatches = isEqual(
      savedTableState?.sorting.filter(sort =>
        defaultTabOrdering?.includes(sort.id)
      ),
      tableState.sorting.filter(sort => defaultTabOrdering?.includes(sort.id))
    );
    const pinsMatch =
      tab === DEFAULT_TAB
        ? isEqual(savedTableState.pinnedColumns, tableState.pinnedColumns)
        : isEqual(
            savedTableState?.pinnedColumns[tab],
            tableState.pinnedColumns[tab]
          );

    const colsMatchDefault = defaultTabOrdering
      ? isEqual(defaultTabOrdering, tableState.columnOrdering[tab])
      : true;
    const columnsMatch = savedTableState?.columnOrdering[tab]
      ? isEqual(
          savedTableState?.columnOrdering[tab],
          tableState.columnOrdering[tab]
        )
      : colsMatchDefault;

    return !sortMatches || !pinsMatch || !columnsMatch;
  },
  unsavedChanges: () => {
    const {
      tableState,
      filterGroups,
      activeSavedScreen,
      hasFilterOptionsChanged,
    } = get();

    const anyFilterOptionsChanged = Object.keys(filterGroups).some(group =>
      hasFilterOptionsChanged(group as ScreenerFilterGroupKeys)
    );

    if (!activeSavedScreen) {
      return anyFilterOptionsChanged;
    }

    const tableStateMatchSaved = () => {
      const columnsMatch = Object.entries(tableState.columnOrdering).every(
        ([name, value]) => {
          return activeSavedScreen.config.tableState.columnOrdering[name]
            ? isEqual(
                activeSavedScreen.config.tableState.columnOrdering[name],
                value
              )
            : true;
        }
      );

      return (
        columnsMatch &&
        (['sorting', 'pinnedColumns'] as const).every(field =>
          isEqual(activeSavedScreen.config.tableState[field], tableState[field])
        )
      );
    };

    return anyFilterOptionsChanged || !tableStateMatchSaved();
  },
  unsavedDraftChanges: () => {
    const { draftScreenFilterGroups } = get();
    return (
      !!draftScreenFilterGroups &&
      Object.values(draftScreenFilterGroups).some(
        filterState => filterState.activeFilterOptions.length
      )
    );
  },
  hasFilterOptionsChanged: group => {
    const { activeSavedScreen, filterGroups } = get();
    const activeFilterOptions = filterGroups[group].activeFilterOptions;
    const config = activeSavedScreen?.config;

    const hasActiveOptions = activeFilterOptions.every(({ options }) =>
      checkIfFirstOptionIsNotChild(options)
    );

    if (!config) {
      return !!activeFilterOptions.length && hasActiveOptions;
    }

    return (
      hasActiveOptions &&
      !isEqual(config.filterOptionGroups?.[group], activeFilterOptions)
    );
  },
  resetActiveFilterOptions: (
    group?: ScreenerFilterGroupKeys,
    resetToDefault?: boolean
  ) => {
    const { filterGroups, activeSavedScreen, tableState } = get();

    if (activeSavedScreen && !resetToDefault) {
      const savedFilterGroups = getSavedScreenFilterGroups(
        filterGroups,
        activeSavedScreen.config
      );
      const newFilterGroups = group
        ? { ...filterGroups, [group]: savedFilterGroups[group] }
        : savedFilterGroups;

      let newTableState = tableState;
      const resetAll = !group;
      const hadKGFilters =
        !!filterGroups['Knowledge Graph Filters'].activeFilterOptions.length;

      if (hadKGFilters && (group === 'Knowledge Graph Filters' || resetAll)) {
        const savedScreenTableState = activeSavedScreen.config.tableState;
        newTableState = resetKGTableState(tableState, savedScreenTableState);
      }

      set({ filterGroups: newFilterGroups, tableState: newTableState });
    } else {
      const keys = group
        ? [group]
        : (Object.keys(filterGroups) as ScreenerFilterGroupKeys[]);
      const newFilterGroups = { ...filterGroups };
      keys.forEach(key => {
        newFilterGroups[key].activeFilterOptions = [];
      });

      set({ filterGroups: newFilterGroups });
    }
  },
  handlePinnedColumns: ({ columnsMeta, pin }) => {
    const updatedPinned = (
      prev: SavedCategorySetting
    ): SavedCategorySetting => {
      const category = columnsMeta[0].category;
      const fields = prev[category] ?? [];
      return {
        ...prev,
        [category]: pin
          ? [...fields, ...columnsMeta.map(c => c.field)]
          : fields.filter(f => columnsMeta.every(c => c.field !== f)),
      };
    };

    set(({ tableState }) => ({
      tableState: {
        ...tableState,
        pinnedColumns: updatedPinned(tableState.pinnedColumns),
      },
    }));
  },
  handleColumnOrdering: ({ tab, fields }) => {
    const updateOrdering = (
      prev: SavedCategorySetting
    ): SavedCategorySetting => {
      return {
        ...prev,
        [tab]: fields,
      };
    };

    set(({ tableState }) => ({
      tableState: {
        ...tableState,
        columnOrdering: updateOrdering(tableState.columnOrdering),
      },
    }));
  },
  handleColumnSorting: (sorting: SortingState) => {
    set(({ tableState }) => ({
      tableState: {
        ...tableState,
        sorting,
      },
    }));
  },
  createDraftScreen: ({ init = false, forceNew = true } = {}) => {
    const { filterGroups, draftScreenFilterGroups } = get();
    let newFilterGroups = draftScreenFilterGroups;

    if (init) {
      newFilterGroups = filterGroups;
    } else if (forceNew || !draftScreenFilterGroups) {
      newFilterGroups = clearActiveFilterOptions(filterGroups);
    }

    set({
      tableState: defaultTableState,
      activeSavedScreen: undefined,
      filterGroups: newFilterGroups,
      draftScreenFilterGroups: newFilterGroups,
    });
  },
  resetTableState: (tab: string) => {
    const { activeSavedScreen, handleColumnSorting, tableState } = get();

    const { sorting, columnOrdering: currentOrdering } = tableState;

    const pinnedColumns = {
      ...tableState.pinnedColumns,
    };

    const columnOrdering = {
      ...tableState.columnOrdering,
    };

    if (!activeSavedScreen) {
      delete pinnedColumns[tab];
      delete columnOrdering[tab];

      handleColumnSorting(
        sorting.filter(sort => !currentOrdering[tab].includes(sort.id))
      );

      set({
        tableState: {
          sorting: [],
          pinnedColumns:
            tab == DEFAULT_TAB
              ? defaultTableState.pinnedColumns
              : pinnedColumns,
          columnOrdering: columnOrdering,
        },
      });
    } else {
      const savedTableState = activeSavedScreen.config.tableState;

      const newSort = [
        ...sorting.filter(sort => !currentOrdering[tab].includes(sort.id)), // keep sorts from other tabs
        ...savedTableState.sorting.filter(
          sort => currentOrdering[tab].includes(sort.id) // add back any sorts for the tab from saved
        ),
      ];

      handleColumnSorting(newSort);

      if (savedTableState.columnOrdering[tab]) {
        columnOrdering[tab] = savedTableState.columnOrdering[tab];
      } else {
        delete columnOrdering[tab];
      }

      if (savedTableState.pinnedColumns[tab]) {
        pinnedColumns[tab] = savedTableState.pinnedColumns[tab];
      } else {
        delete pinnedColumns[tab];
      }

      set(({ tableState }) => ({
        tableState: {
          ...tableState,
          columnOrdering: columnOrdering,
          pinnedColumns:
            tab == DEFAULT_TAB ? savedTableState.pinnedColumns : pinnedColumns,
        },
      }));
    }
  },
  canCreateNewScreen: () => {
    const { draftScreenFilterGroups, unsavedDraftChanges } = get();
    return !draftScreenFilterGroups || unsavedDraftChanges();
  },
  shouldPromptUnsavedChanges: () => {
    const { unsavedChanges, activeSavedScreen, isWorkingListActive } = get();
    return !isWorkingListActive() && unsavedChanges() && !!activeSavedScreen;
  },
  isWorkingListActiveInDraft: () => {
    const { draftScreenFilterGroups } = get();
    const assetsFilter = draftScreenFilterGroups?.[
      'Market Filters'
      ].activeFilterOptions.find(f => f.id === ASSET_FILTERS_KEY);

    return !!assetsFilter?.options.some(o => o.key === WORKING_LIST_ID);
  },
  isWorkingListActive: () => {
    const { filterGroups } = get();
    const assetsFilter = filterGroups[
      'Market Filters'
    ].activeFilterOptions.find(f => f.id === ASSET_FILTERS_KEY);

    return !!assetsFilter?.options.some(o => o.key === WORKING_LIST_ID);
  },
}));
